import React from "react";
import "./slide.scss";
import { SlideObject, SlideTypes } from "./SlideObjects/SlideStructures";
import SlideHistory, { changeTypes } from "./SlideHistory";
import MenuObject from "./MenuObject";
import ScalePanel from "./ScalePanel";
import ContentPanel2 from "./ContentPanel2";
import Selector from "./SlideObjects/Selector";
import Delete from "./components/IconButtons/Delete";
import DrawComponent from "./components/LineComponents/DrawComponent";
import SectionName from "./components/Dialog/SectionName";
import request from "superagent";
import { ReactSortable } from "react-sortablejs";

import { MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import HeaderDefault from "./SlideHeader/HeaderDefault";
import { InsertImages, Cut, Copy, Paste } from "./components/IconButtons/SubMenuIcons";
import ContextMenu from "./components/menus/newContextMenu/ContextMenu";
import SCContextMenuPortal from "./components/menus/newContextMenu/SCContextMenuPortal";
import TemplateButtonsPortal from "./components/Portals/TemplateButtonsPortal";
import LogoComponent from "./LogoComponent";
import ContentPanelBusinessPlan from "../../BusinessPlan/ContentPanelBusinessPlan";
import BusinessPlanDraggable from "./BusinessPlanDraggable";
import BPPreviewScalePanel from "../../BusinessPlan/components/BP_Preview_ScalePanel";
import CustomSortable from "../../BusinessPlan/components/CustomSortable";
import MxIDUseID from "../../helpers/MxIUniqueD";
import {
  updateThemeBackgroundForBusinessPlan,
  updateThemeBackgroundWithPitchScenario,
} from "../services/businessPlanFlows";
import { SlideObjectStrategy, SlideObjectStrategyTypes } from "../services/slideObjectStrategy";
import { addItemAtIndex, arrayMove, array_move } from "../../helpers/utils/array";
import { KeyEventsHandler } from "../../helpers/KeyEventsHandler";
import { KeyboardEventKeys } from "../../helpers/enums";
import { CommandHistoryManager } from "../services/commands/CommandManager";
import { AddObject } from "../services/commands/AddObject";
import { CommandManagerContext } from "../services/commands/CommandManagerProvider";
import { MoveSelectedObjects } from "../services/commands/MoveSelectedObjects";
import { Swap } from "../services/commands/Swap";

async function getPermission(callback) {
  try {
    const permission = await navigator.permissions.query({ name: "clipboard-read" });
    if (permission.state === "denied") {
      return false;
    } else {
      const clipboardContents = await navigator.clipboard.read().then((val) => {
        for (const item of val) {
          callback(item);

          // item.getType('text/plain').then(valItem => {
          //     valItem.text().then(text => {
          //         callback(text)
          //     });
          // })
        }
      });
    }
  } catch (error) {
    console.error(error.message);
  }
}

class SlideComponent extends React.Component {
  AccountID = null;
  CompanyID = null;
  PitchScenarioID = null;

  constructor(props) {
    super(props);
    this.selectorActive = false;
    this.selectorRef = React.createRef();
    this.keyEventsHandler = null;
    this.didMoveHappen = false;
    this.insertMode = null;

    this.boundries = {
      x: [],
      y: [],
    };
    this.recalculateSlideObjectBoundaries = this.recalculateSlideObjectBoundaries.bind(this);
    this.buildBoundingRectangles = this.buildBoundingRectangles.bind(this);
    this.buildBoundingRectangles();

    this.slideObjectStrategy = new SlideObjectStrategy(
      props.businessPlan ? SlideObjectStrategyTypes.BusinessPlan : SlideObjectStrategyTypes.Pitch,
    );

    if (!this.props.publishMode) {
      if (global.Modeliks.Account) {
        this.AccountID = global.Modeliks.Account.ID;
        this.CompanyID = global.Modeliks.CompanyInfo.ID;

        if (this.props.businessPlan) {
          this.PitchScenarioID = global.Modeliks.BusinessPlanScenarioInfo.ID;
        }
        if (this.props.PitchScenarioInfo) {
          this.PitchScenarioID = this.props.PitchScenarioInfo.ID;
        }
      }
    }
    this.lastPasteEventTimeStamp = 0;

    global.slideHistory = new SlideHistory(CommandHistoryManager.getInstance());
    this.slideHistoryListener = global.slideHistory.subscribe(this.setupUndoRedoActions.bind(this));
    this.logoConatiner = React.createRef();

    this.state = {
      anchorPoint: { x: 0, y: 0 },
      menuProps: undefined,
      changes: [],
      left: null,
      top: null,
      slideObjects: [],
      firstObjects: [],
      selectedObject: null,
      selectedObjects: [],
      activeFormatPainter: false,
      formatPainterStyle: {},
      base64str:
        this.props.publishMode && this.props.slideConfig.src
          ? btoa(
              new Uint8Array(this.props.slideConfig.src.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                "",
              ),
            )
          : "",
      style: this.getStyle2(),
      minZindex: 500,
      maxZindex: 501,
      scribble: false,
      canUndo: global.slideHistory.hasUndoHistory(),
      canRedo: global.slideHistory.hasRedoHistory(),
    };

    const data = this.props.jsonData;
    if (data) {
      const savedData = JSON.parse(data);
      if (savedData.slideObjects) {
        savedData.slideObjects.forEach((obj, index) => {
          if (obj.props.zIndeX) {
            if (obj.props.zIndeX > this.state.maxZindex) {
              this.state.maxZindex = obj.props.zIndeX;
            }
          } else {
            obj.props.zIndeX = this.state.maxZindex + 1;
          }
          if (!obj.props.staticKey) {
            obj.props.staticKey = "so_" + MxIDUseID();
          }

          this.state.slideObjects.push(
            new SlideObject(
              obj.type,
              obj.value,
              {
                ...obj.props,
                oldKey: obj.key,
                replacementImage: true,
              },
              false,
              false,
            ),
          );

          this.recalculateSlideObjectBoundaries(
            this.state.slideObjects[this.state.slideObjects.length - 1],
          );
        });

        // this.forceUpdate();
      } else {
        this.state.slideObjects = [];
      }

      // this.state.style.background = savedData.slideBg;
    }

    this.loaded = false;

    this.hasCopiedObjects = false;
    this.copiedObject = null;
    this.copiedObjects = null;

    this.props.scale.updateSlideComp = this.updateMe;
  }

  setupUndoRedoActions = () => {
    this.setState({
      canUndo: global.slideHistory.hasUndoHistory(),
      canRedo: global.slideHistory.hasRedoHistory(),
    });
  };

  getStyle2 = () => {
    if (!this.props.publishMode) {
      if (!this.props.businessPlan) {
        if (this.props.selectedTemplateColor) {
          return { background: this.props.selectedTemplateColor };
          // return {background: this.props.selectedTemplateColor}
        }
        if (this.props.PitchScenarioInfo && this.props.PitchScenarioInfo.BackgroundColor) {
          return { background: this.props.PitchScenarioInfo.BackgroundColor };
        }
      } else if (
        global.Modeliks.BusinessPlanConfig &&
        global.Modeliks.BusinessPlanConfig.BackgroundColor
      ) {
        return { background: global.Modeliks.BusinessPlanConfig.BackgroundColor };
      }
      return { background: "#ffffff" };

      // return {background: !this.props.businessPlan ?
      //         this.props.selectedTemplateColor ? this.props.selectedTemplateColor
      //             : (this.props.PitchScenarioInfo && this.props.PitchScenarioInfo.BackgroundColor) ?
      //                 this.props.PitchScenarioInfo.BackgroundColor
      //                 : '#ffffff'
      //         : (global.Modeliks.BusinessPlanConfig && global.Modeliks.BusinessPlanConfig.BackgroundColor) ?
      //             global.Modeliks.BusinessPlanConfig.BackgroundColor
      //             : '#ffffff'}
    }

    return {
      background: this.props.slideConfig.BackgroundColor
        ? this.props.slideConfig.BackgroundColor
        : "#ffffff",
    };
  };

  getSlideStyle = () => {
    //            style: !this.props.publishMode ? {} : {background: this.props.slideConfig.BackgroundColor ? this.props.slideConfig.BackgroundColor : '#ffffff'},
    if (this.PitchScenarioID) {
      if (this.props.publishMode) {
        return {
          background: this.props.PitchScenarioInfo.BackgroundColor
            ? this.props.PitchScenarioInfo.BackgroundColor
            : "#ffffff",
        };
      } else {
        if (this.props.slideConfig) {
          return {
            background: this.props.slideConfig.BackgroundColor
              ? this.props.slideConfig.BackgroundColor
              : "#ffffff",
          };
        } else return { background: "#ffffff" };
      }
    }
    return { background: "#ffffff" };
  };

  updateMe = () => {
    this.forceUpdate();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !this.props.isAdmin &&
      (JSON.stringify(nextState.selectedObject) != JSON.stringify(this.state.selectedObject) ||
        JSON.stringify(nextState.selectedObjects) != JSON.stringify(this.state.selectedObjects))
    ) {
      this.handleOnSave(false);
    }
    return true;
  }

  clickHandler = (event) => {
    const rect = document.getElementById("se_scale_panel").getBoundingClientRect();
    const offsetMouse = 1 / window.panelScale;
    let { clientX: startX, clientY: startY } = event;

    startX = (startX - rect.left) * offsetMouse;
    startY = (startY - rect.top) * offsetMouse;

    this.insertMode.insertFunction(startY, startX);
    this.insertMode = null;

    document.removeEventListener("click", this.clickHandler);
    document.getElementById("se_scale_panel").classList.remove("insertMode");
  };

  setInsertMode = (type, insertFunction) => {
    if (this.props.businessPlan && type == SlideTypes.textObject) {
      insertFunction && insertFunction();
      return;
    }
    this.insertMode = {
      type: type,
      insertFunction: insertFunction,
    };

    this.forceUpdate();

    setTimeout(() => document.addEventListener("click", this.clickHandler), 1);
    document.getElementById("se_scale_panel").classList.add("insertMode");
  };

  handleContextMenu = (e) => {
    this.setState({
      menuProps: true,
      anchorPoint: { x: e.pageX, y: e.pageY },
    });
    // this.forceUpdate()
  };
  handleClose = (callBack) => {
    this.setState({ anchorPoint: { x: 0, y: 0 }, menuProps: undefined }, callBack);
    // this.forceUpdate()
  };

  returnJsonString = () => {
    let JsonString = JSON.stringify({
      slideObjects: this.state.slideObjects,
      slideBg: this.state.style.background,
    });

    return JsonString;
  };

  handleOutsideMouseDown = (e) => {
    if (e.target.className === "se_panel_container")
      this.setState({ selectedObject: null, selectedObjects: [] });
  };

  preventEventDefault = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }

    if (!this.props.publishMode && !this.props.preview && !this.props.disableEdit) {
      document
        .getElementById("se_panel_container")
        .addEventListener("mousedown", this.handleOutsideMouseDown);

      this.keyEventsHandler = new KeyEventsHandler(this.props);
      this.keyEventsHandler.attachEventListeners();

      this.setupKeyHandlers();

      global.pasteListener = this.handlePaste;
      document.addEventListener("contextmenu", this.preventEventDefault);
    }

    if (this.loaded == false) {
      this.loaded = true;
      this.props.didMount && this.props.didMount();
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    if (!this.props.publishMode && !this.props.preview && !this.props.disableEdit) {
      document
        .getElementById("se_panel_container")
        .removeEventListener("mousedown", this.handleOutsideMouseDown);
      this.keyEventsHandler.removeEventListeners();
      document.removeEventListener("contextmenu", this.preventEventDefault);
    }
    global.pasteListener = null;
    // reset the slidehistory here
    global.slideHistory.reset();
    this.slideHistoryListener();
  }

  updateSlideProps = (key) => {
    let slideObj = this.state.slideObjects.filter((c) => c.key === key)[0];
    if (
      this.state.changes.length === 0 ||
      JSON.stringify(slideObj.props) !=
        JSON.stringify(this.state.changes[this.state.changes.length - 1].props)
    ) {
      this.state.changes.push({ key: key, props: { ...slideObj.props } });
      this.index = this.state.changes.length - 1;
    }
  };

  handleOnNewTemplateObject = (data) => {
    const savedData = JSON.parse(data);
    savedData.slideObjects.forEach((obj) =>
      this.state.slideObjects.push(new SlideObject(obj.type, obj.value, obj.props)),
    );
    // this.state.style.background = savedData.slideBg;
  };
  margin = 10;

  buildBX = (x, style, margin = null, objKey) => {
    let sX = x - this.margin;
    if (sX < 0) {
      sX = 0;
    }

    let eX = x + this.margin;
    if (eX > 1280) {
      eX = 1280;
    }

    const res = {
      xmin: sX,
      xmax: eX,
      x: x,
      objKey: objKey,
      active: {},
    };
    res.active[style] = margin != null ? margin : x;

    return res;
  };

  buildBY = (y, style, margin = null, objKey) => {
    let sY = y - this.margin;
    if (sY < 0) {
      sY = 0;
    }

    let eY = y + this.margin;
    if (eY > 720) {
      eY = 720;
    }

    const res = {
      ymin: sY,
      ymax: eY,
      objKey: objKey,
      y: y,
      active: {},
    };
    res.active[style] = margin != null ? margin : y;

    return res;
  };

  buildBoundingRectangles() {
    this.boundries.x.push(this.buildBX(0, "activeFrame", 0));
    this.boundries.x.push(this.buildBX(40, "activeFrame", 40));
    this.boundries.x.push(this.buildBX(1280, "activeFrame", 0));
    this.boundries.x.push(this.buildBX(1240, "activeFrame", 40));

    this.boundries.y.push(this.buildBY(0, "activeFrame", 0));
    this.boundries.y.push(this.buildBY(40, "activeFrame", 40));
    this.boundries.y.push(this.buildBY(720, "activeFrame", 0));
    this.boundries.y.push(this.buildBY(680, "activeFrame", 40));
  }

  addNewObject = (newObject) => {
    this.state.slideObjects.push(newObject);
    newObject = this.state.slideObjects[this.state.slideObjects.length - 1];
    newObject.props.zIndeX = this.state.maxZindex + 1;
    this.setState({
      selectedObject: newObject,
      maxZindex: this.state.maxZindex + 1,
      selectedObjects: [],
    });
    this.recalculateSlideObjectBoundaries(newObject);
    return newObject;
  };

  addNewBpObject = (newObject) => {
    // FIXME: simplify and refactor
    let slideComp = document.getElementById("scale_panel_container").getBoundingClientRect();
    let slideCompOffset = (slideComp.top - 160) / window.panelScale;
    let newTopValue = Math.abs(slideCompOffset) + 200;
    if (newTopValue + newObject.props.height >= 3507) {
      newTopValue = 3507 - newObject.props.height - 30;
    }
    newObject.props.top = newTopValue;
    if (this.state.selectedObject && this.state.selectedObject !== "panel") {
      let index = this.state.slideObjects.indexOf(this.state.selectedObject);
      this.state.slideObjects.splice(index + 1, 0, newObject);
      newObject = this.state.slideObjects[index + 1];
      newObject.props.zIndeX = this.state.maxZindex + 1;
      this.setState({
        selectedObject: newObject,
        maxZindex: this.state.maxZindex + 1,
        selectedObjects: [],
      });
      this.recalculateSlideObjectBoundaries(newObject);
    } else if (this.state.selectedObjects.length > 1) {
      let index = this.state.slideObjects.indexOf(
        this.state.selectedObjects[this.state.selectedObjects.length - 1],
      );
      this.state.slideObjects.splice(index + 1, 0, newObject);
      newObject = this.state.slideObjects[index + 1];
      newObject.props.zIndeX = this.state.maxZindex + 1;
      this.setState({
        selectedObject: newObject,
        maxZindex: this.state.maxZindex + 1,
        selectedObjects: [],
      });
      this.recalculateSlideObjectBoundaries(newObject);
    } else {
      this.state.slideObjects.push(newObject);
      newObject = this.state.slideObjects[this.state.slideObjects.length - 1];
      newObject.props.zIndeX = this.state.maxZindex + 1;
      this.setState({
        selectedObject: newObject,
        maxZindex: this.state.maxZindex + 1,
        selectedObjects: [],
      });
      this.recalculateSlideObjectBoundaries(newObject);
    }
    this.props.scale.handleResize();

    return newObject;
  };

  handleOnNewObject = async (nObj) => {
    if (!this.props.disableEdit) {
      nObj.props.staticKey = "so_" + MxIDUseID();
      if (!this.props.businessPlan) {
        const addNewObjectCommand = new AddObject({
          actionBackward: this.handleRemoveSlideObject.bind(this),
          actionForward: this.addNewObject.bind(this),
          object: nObj,
        });

        await this.context.commandHistoryManager.executeCommand(addNewObjectCommand);
        this.context.addToGlobalSlideHistory();
      } else {
        const addNewObjectCommand = new AddObject({
          actionBackward: this.handleRemoveSlideObject.bind(this),
          actionForward: this.addNewBpObject.bind(this),
          object: nObj,
        });

        await this.context.commandHistoryManager.executeCommand(addNewObjectCommand);
        this.context.addToGlobalSlideHistory();
      }
    }
  };

  handleUploadImage = (file, size = 0) => {
    const style = { borderColor: "", top: 100 / 2, left: 100, zIndeX: this.state.maxZindex + 1 };
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64data;
    reader.onloadend = () => {
      base64data = reader.result;
      this.handleOnNewObject(new SlideObject(SlideTypes.imageObject, base64data, style));
      this.setState({ maxZindex: this.state.maxZindex + 1 });
    };
  };

  handleReplaceImage = (file, index) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64data;
    reader.onloadend = () => {
      base64data = reader.result;
      let newObj = this.state.slideObjects[index];
      newObj.value = base64data;
      this.state.slideObjects[index].updateValue(base64data);
      this.state.slideObjects.push(
        new SlideObject(SlideTypes.imageObject, base64data, {
          ...this.state.slideObjects[index].props,
          currentImageSrc: null,
          replacementImage: true,
        }),
      );
      this.handleRemoveSlideObject(this.state.slideObjects[index]);
      this.forceUpdate();
      // return base64data;
    };
  };

  handleAddTable = (cols, rows) => {
    const colsC = cols;
    const rowsC = rows;
    let TableStructure = [];

    const table1 = `{"colCount": ${colsC},"rowCount":${rowsC},"colWidths":[],"rowHeights":[],
        "rows":[${TableStructure}]}`;

    let top = 300;
    let left = 60;
    let width = 870;
    let height = rows * 25;
    let s = {
      structure: JSON.parse(table1),
      style: {
        fontSize: 45,
        fontWeight: "300",
        lineHeight: "auto",
        // height: 'auto',
        fontFamily: "Inter",
      },
      zIndeX: this.state.maxZindex + 1,
      top,
      left,
      width,
      minHeight: height,
      height,
    };
    this.handleOnNewObject(new SlideObject(SlideTypes.tableObject, null, s));
    // this.state.selectedObject = s;
    // this.setState({selectedObject: s, maxZindex: this.state.maxZindex + 1});

    this.forceUpdate();
  };

  pasteGroupObject = (slideObject, shouldPasteInPlace = true) => {
    if (shouldPasteInPlace) {
      if (this.props.shouldPasteInPlace) {
        if (this.props.timesPasted > 0) {
          slideObject.props.top = slideObject.props.top + this.props.timesPasted * 30;
          slideObject.props.left = slideObject.props.left + this.props.timesPasted * 30;
          this.props.setTimesPasted(this.props.timesPasted + 1);
        } else {
          this.props.setTimesPasted(this.props.timesPasted + 1);
        }
      } else {
        slideObject.props.top = slideObject.props.top + (this.props.timesPasted + 1) * 30;
        slideObject.props.left = slideObject.props.left + (this.props.timesPasted + 1) * 30;
        this.props.setTimesPasted(this.props.timesPasted + 1);
      }
    }

    let newGroupKey = "group_key_" + MxIDUseID();

    this.state.slideObjects.push(
      new SlideObject(slideObject.type, slideObject.value, {
        ...slideObject.props,
        oldKey: slideObject.key,
        zIndeX: this.state.maxZindex + 1,
        staticKey: "so_" + MxIDUseID(),
        groupKey: newGroupKey,
        replacementImage: true,
      }),
    );

    slideObject.value.forEach((c) => {
      this.state.slideObjects.push(
        new SlideObject(c.type, c.value, {
          ...c.props,
          oldKey: c.key,
          zIndeX: this.state.maxZindex + 1,
          groupKey: newGroupKey,
          staticKey: "so_" + MxIDUseID(),
          replacementImage: true,
        }),
      );
    });

    this.forceUpdate();
  };

  handlePaste = (event, isCalledFromObject = false) => {
    this.handleClose();

    if (this.lastPasteEventTimeStamp != event.timeStamp) {
      this.lastPasteEventTimeStamp = event.timeStamp;
    } else {
      return;
    }
    event.stopPropagation();
    if (
      (this.state.selectedObjects.length === 0 &&
        (!this.state.selectedObject || this.state.selectedObject === "panel")) ||
      isCalledFromObject
    ) {
      if (event.clipboardData) {
        if (
          event.clipboardData.getData("text") &&
          (!event.clipboardData.getData("text/html") ||
            event.clipboardData.getData("text/html").indexOf("<table") === -1)
        ) {
          let data = event.clipboardData.getData("text");

          if (data.includes("cutObjects")) {
            let objData = JSON.parse(data);
            let slideObjects = objData.cutObjects;

            slideObjects.forEach((slideObject) => {
              this.state.slideObjects.push(
                new SlideObject(slideObject.type, slideObject.value, {
                  ...slideObject.props,
                  oldKey: slideObject.key,
                  staticKey: "so_" + MxIDUseID(),
                  replacementImage: true,
                }),
              );
            });

            this.forceUpdate();
          } else if (data.includes("cutObject")) {
            let objData = JSON.parse(data);
            let slideObject = objData.cutObject;
            this.state.slideObjects.push(
              new SlideObject(slideObject.type, slideObject.value, {
                ...slideObject.props,
                oldKey: slideObject.key,
                staticKey: "so_" + MxIDUseID(),
                replacementImage: true,
              }),
            );
            this.forceUpdate();
          } else if (data.includes("copyObjects")) {
            let objData = JSON.parse(data);
            let slideObjects = objData.copyObjects;

            slideObjects.forEach((slideObject, index) => {
              if (slideObject.type !== SlideTypes.GroupObject) {
                if (this.props.shouldPasteInPlace) {
                  if (this.props.timesPasted > 0) {
                    slideObject.props.top = slideObject.props.top + this.props.timesPasted * 30;
                    slideObject.props.left = slideObject.props.left + this.props.timesPasted * 30;
                    this.props.setTimesPasted(this.props.timesPasted + 1);
                  } else {
                    this.props.setTimesPasted(this.props.timesPasted + 1);
                  }
                } else {
                  slideObject.props.top = slideObject.props.top + (this.props.timesPasted + 1) * 30;
                  slideObject.props.left =
                    slideObject.props.left + (this.props.timesPasted + 1) * 30;
                  this.props.setTimesPasted(this.props.timesPasted + 1);
                }

                this.state.slideObjects.push(
                  new SlideObject(slideObject.type, slideObject.value, {
                    ...slideObject.props,
                    oldKey: slideObject.key,
                    zIndeX: this.state.maxZindex + 1,
                    staticKey: "so_" + MxIDUseID(),
                    replacementImage: true,
                  }),
                );
              } else {
                this.pasteGroupObject(slideObject);
              }
            });

            this.state.maxZindex = this.state.maxZindex + 1;

            this.forceUpdate();
          } else if (data.includes("copyObject")) {
            let objData = JSON.parse(data);
            let slideObject = objData.copyObject;

            if (slideObject.type != SlideTypes.GroupObject) {
              if (this.props.shouldPasteInPlace) {
                if (this.props.timesPasted > 0) {
                  slideObject.props.top = slideObject.props.top + this.props.timesPasted * 30;
                  slideObject.props.left = slideObject.props.left + this.props.timesPasted * 30;
                  this.props.setTimesPasted(this.props.timesPasted + 1);
                } else {
                  this.props.setTimesPasted(this.props.timesPasted + 1);
                }
              } else {
                slideObject.props.top = slideObject.props.top + (this.props.timesPasted + 1) * 30;
                slideObject.props.left = slideObject.props.left + (this.props.timesPasted + 1) * 30;
                this.props.setTimesPasted(this.props.timesPasted + 1);
              }

              this.state.slideObjects.push(
                new SlideObject(slideObject.type, slideObject.value, {
                  ...slideObject.props,
                  oldKey: slideObject.key,
                  zIndeX: this.state.maxZindex + 1,
                  staticKey: "so_" + MxIDUseID(),
                  replacementImage: true,
                }),
              );
            } else {
              this.pasteGroupObject(slideObject);
            }

            this.state.maxZindex = this.state.maxZindex + 1;

            this.forceUpdate();
          } else if (data.includes("youtube")) {
            let indexOfAnd = data.indexOf("&");
            let embedCode = data.substring(
              data.indexOf("?v=") + 3,
              indexOfAnd !== -1 ? indexOfAnd : data.length,
            );
            let iframe = `<iframe width="98%" height="98%" src="https://www.youtube.com/embed/${embedCode}" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`;

            this.state.slideObjects.push(
              new SlideObject(SlideTypes.htmlObject, iframe, {
                style: {
                  width: 562,
                  height: 332,
                },
                zIndeX: this.state.maxZindex + 1,
                staticKey: "so_" + MxIDUseID(),
              }),
            );
            this.setState({ maxZindex: this.state.maxZindex + 1 });
          } else if (data.indexOf("\t") > -1) {
            const rows = data.split("\n");
            let headers = [];
            let dataRows = [];
            let newRows = [];
            let colWidths = [];
            let rowHeights = [];
            rows.forEach((row, index) => {
              if (index !== rows.length - 1) {
                rowHeights.push("40px");
              }
              let cols = [];
              if (row != "") {
                let splittedRow = row.split("\t");

                splittedRow.forEach((val) => {
                  if (index === 0) {
                    colWidths.push(870 / splittedRow.length + "px");
                  }
                  cols.push({ value: val, style: {} });
                });

                newRows.push(cols);
              }

              if (index + 1 === rows.length) {
                let top = 60;
                let left = 60;
                let width = 870;
                let height = newRows.length * 40;
                const props = {
                  structure: {
                    colCount: cols.length,
                    rowCount: newRows.length,
                    rows: newRows,
                    colWidths: colWidths,
                    rowHeights: rowHeights,
                  },
                  style: {
                    fontSize: 30,
                    fontWeight: "300",
                    lineHeight: "auto",
                    height: "auto",
                    fontFamily: "Inter",
                  },
                  top,
                  left,
                  width,
                  height,
                  minHeight: height,
                  zIndeX: this.state.maxZindex + 1,
                };
                this.handleOnNewObject(new SlideObject(SlideTypes.tableObject, null, props));
                this.setState({ maxZindex: this.state.maxZindex + 1 });
              }
            });
          } else {
            let s = {
              style: {
                lineHeight: "auto",
                height: "100%",
                width: "100%",
                fontFamily: "Inter",
                fontWeight: "300",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                textAlign: "left",
              },
              left: 100,
              top: 460,
              width: 450,
            };
            this.state.slideObjects.push(
              new SlideObject(SlideTypes.textObject, data, {
                struct: [
                  {
                    startIndex: 0,
                    length: 15,
                    style: { fontSize: "30px" },
                    characters: [
                      {
                        startIndex: 0,
                        length: data.length,
                        char: data,
                        style: { fontSize: "30px" },
                      },
                    ],
                  },
                ],
                ...s,
                zIndeX: this.state.maxZindex + 1,
                staticKey: "so_" + MxIDUseID(),
              }),
            );
            this.setState({ maxZindex: this.state.maxZindex + 1 });
          }
        } else if (
          event.clipboardData.files[0] &&
          (!event.clipboardData.getData("text/html") ||
            event.clipboardData.getData("text/html").indexOf("<table") === -1)
        ) {
          var reader = new FileReader();
          reader.readAsDataURL(event.clipboardData.files[0]);
          reader.onloadend = () => {
            var img = new Image();
            img.src = reader.result;

            img.onload = () => {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');

              // Calculate the new dimensions
              var maxDimension = 1000; // Adjust this value to control the max width/height
              var width = img.width;
              var height = img.height;

              if (width > height) {
                if (width > maxDimension) {
                  height *= maxDimension / width;
                  width = maxDimension;
                }
              } else {
                if (height > maxDimension) {
                  width *= maxDimension / height;
                  height = maxDimension;
                }
              }

              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(img, 0, 0, width, height);

              // Convert to base64 with quality control
              var base64data = canvas.toDataURL('image/jpeg', 0.8); // Adjust the quality as needed

              // Check the size and adjust if necessary
              while (base64data.length > 1 * 1024 * 1024) { // 1MB
                base64data = canvas.toDataURL('image/jpeg', 0.7); // Reduce quality
              }

              this.state.slideObjects.push(
                new SlideObject(SlideTypes.imageObject, base64data, {
                  height: 'auto',
                  zIndeX: this.state.maxZindex + 1,
                  staticKey: 'so_' + MxIDUseID(),
                })
              );
              this.setState({ maxZindex: this.state.maxZindex + 1 });
            };
          };
        } else if (event.clipboardData.items[0] || event.clipboardData.getData("text/html")) {
          if (
            event.clipboardData.items[0].type.includes("html") ||
            event.clipboardData.getData("text/html")
          ) {
            if (event.clipboardData.getData("text/html")) {
              let parser = new DOMParser();
              const doc = parser.parseFromString(
                event.clipboardData.getData("text/html"),
                "text/html",
              );
              if (event.clipboardData.getData("text/html").indexOf("<table") > -1) {
                let colWidths = [];
                let rowHeights = [];

                let body = doc.body;

                let table = body.getElementsByTagName("table")[0];
                let trs = table.getElementsByTagName("tr");

                let rows = [];

                for (let i = 0; i < trs.length; i++) {
                  rowHeights.push("40px");
                  let cells = trs[i].getElementsByTagName("td");
                  let cols = [];
                  for (let j = 0; j < cells.length; j++) {
                    if (i === 0) {
                      colWidths.push(870 / cells.length + "px");
                    }
                    cols.push({ value: cells[j].innerText, style: {} });
                  }
                  rows.push(cols);
                }

                let top = 60;
                let left = 60;
                let width = 870;
                let height = rows.length * 40;
                const props = {
                  structure: {
                    colCount: rows[0].length,
                    rowCount: rows.length,
                    rows: rows,
                    colWidths: colWidths,
                    rowHeights: rowHeights,
                  },
                  style: {
                    fontSize: 30,
                    fontWeight: "300",
                    lineHeight: "auto",
                    height: "auto",
                    fontFamily: "Inter",
                  },
                  top,
                  left,
                  width,
                  height,
                  minHeight: height,
                  zIndeX: this.state.maxZindex + 1,
                };
                this.handleOnNewObject(new SlideObject(SlideTypes.tableObject, null, props));
                this.setState({ maxZindex: this.state.maxZindex + 1 });
              } else {
                this.changeDocElementsStyle(doc);
                this.state.slideObjects.push(
                  new SlideObject(SlideTypes.htmlObject, doc.children[0].children[1].innerHTML, {
                    zIndeX: this.state.maxZindex + 1,
                    staticKey: "so_" + MxIDUseID(),
                  }),
                );
                this.setState({ maxZindex: this.state.maxZindex + 1 });
              }
            } else {
              this.state.slideObjects.push(
                new SlideObject(SlideTypes.htmlObject, event.clipboardData.getData("Text"), {
                  zIndeX: this.state.maxZindex + 1,
                  staticKey: "so_" + MxIDUseID(),
                }),
              );
              this.setState({ maxZindex: this.state.maxZindex + 1 });
            }
          } else if (event.clipboardData.items[0].type.includes("text")) {
            this.state.slideObjects.push(
              new SlideObject(SlideTypes.textObject, event.clipboardData.getData("text/plain"), {
                zIndeX: this.state.maxZindex + 1,
                staticKey: "so_" + MxIDUseID(),
              }),
            );
            this.setState({ maxZindex: this.state.maxZindex + 1 });
          }
        }
      } else if (navigator.clipboard) {
        let permission = getPermission(this.handlePasteAwait);
      }
    }
  };

  handlePasteAwait = (item) => {
    if (item.types.includes("text/plain")) {
      item.getType("text/plain").then((valItem) => {
        valItem.text().then((data) => {
          if (data) {
            if (data.includes("cutObjects")) {
              let objData = JSON.parse(data);
              let slideObjects = objData.cutObjects;

              slideObjects.forEach((slideObject) => {
                this.state.slideObjects.push(
                  new SlideObject(slideObject.type, slideObject.value, {
                    ...slideObject.props,
                    oldKey: slideObject.key,
                    staticKey: "so_" + MxIDUseID(),
                    replacementImage: true,
                  }),
                );
              });

              this.forceUpdate();
            } else if (data.includes("cutObject")) {
              let objData = JSON.parse(data);
              let slideObject = objData.cutObject;
              this.state.slideObjects.push(
                new SlideObject(slideObject.type, slideObject.value, {
                  ...slideObject.props,
                  oldKey: slideObject.key,
                  staticKey: "so_" + MxIDUseID(),
                  replacementImage: true,
                }),
              );
              this.forceUpdate();
            } else if (data.includes("copyObjects")) {
              let objData = JSON.parse(data);
              let slideObjects = objData.copyObjects;

              slideObjects.forEach((slideObject) => {
                if (this.props.shouldPasteInPlace) {
                  if (this.props.timesPasted > 0) {
                    slideObject.props.top = slideObject.props.top + this.props.timesPasted * 30;
                    slideObject.props.left = slideObject.props.left + this.props.timesPasted * 30;
                    this.props.setTimesPasted(this.props.timesPasted + 1);
                  } else {
                    this.props.setTimesPasted(this.props.timesPasted + 1);
                  }
                } else {
                  slideObject.props.top = slideObject.props.top + (this.props.timesPasted + 1) * 30;
                  slideObject.props.left =
                    slideObject.props.left + (this.props.timesPasted + 1) * 30;
                  this.props.setTimesPasted(this.props.timesPasted + 1);
                }

                this.state.slideObjects.push(
                  new SlideObject(slideObject.type, slideObject.value, {
                    ...slideObject.props,
                    zIndeX: this.state.maxZindex + 1,
                    oldKey: slideObject.key,
                    staticKey: "so_" + MxIDUseID(),
                    replacementImage: true,
                  }),
                );
              });

              this.state.maxZindex = this.state.maxZindex + 1;

              this.forceUpdate();
            } else if (data.includes("copyObject")) {
              let objData = JSON.parse(data);
              let slideObject = objData.copyObject;

              if (this.props.shouldPasteInPlace) {
                if (this.props.timesPasted > 0) {
                  slideObject.props.top = slideObject.props.top + this.props.timesPasted * 30;
                  slideObject.props.left = slideObject.props.left + this.props.timesPasted * 30;
                  this.props.setTimesPasted(this.props.timesPasted + 1);
                } else {
                  this.props.setTimesPasted(this.props.timesPasted + 1);
                }
              } else {
                slideObject.props.top = slideObject.props.top + (this.props.timesPasted + 1) * 30;
                slideObject.props.left = slideObject.props.left + (this.props.timesPasted + 1) * 30;
                this.props.setTimesPasted(this.props.timesPasted + 1);
              }

              this.state.slideObjects.push(
                new SlideObject(slideObject.type, slideObject.value, {
                  ...slideObject.props,
                  oldKey: slideObject.key,
                  zIndeX: this.state.maxZindex + 1,
                  staticKey: "so_" + MxIDUseID(),
                  replacementImage: true,
                }),
              );

              this.state.maxZindex = this.state.maxZindex + 1;

              this.forceUpdate();
            } else if (data.includes("youtube")) {
              let indexOfAnd = data.indexOf("&");
              let embedCode = data.substring(
                data.indexOf("?v=") + 3,
                indexOfAnd !== -1 ? indexOfAnd : data.length,
              );
              let iframe = `<iframe width="98%" height="98%" src="https://www.youtube.com/embed/${embedCode}" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`;

              this.state.slideObjects.push(
                new SlideObject(SlideTypes.htmlObject, iframe, {
                  style: {
                    width: 562,
                    height: 332,
                  },
                  zIndeX: this.state.maxZindex + 1,
                  staticKey: "so_" + MxIDUseID(),
                }),
              );
              this.setState({ maxZindex: this.state.maxZindex + 1 });
            } else {
              const rows = data.split("\n");
              let headers = [];
              let dataRows = [];
              let newRows = [];
              let colWidths = [];
              let rowHeights = [];
              rows.forEach((row, index) => {
                rowHeights.push("auto");
                let cols = [];
                if (row != "") {
                  let splittedRow = row.split("\t");

                  splittedRow.forEach((val) => {
                    cols.push({ value: val, style: {} });
                  });

                  newRows.push(cols);
                }

                if (index + 1 === rows.length) {
                  let top = 60;
                  let left = 60;
                  let width = 870;
                  let height = 450;
                  cols.forEach((col) => {
                    colWidths.push("auto");
                  });
                  const props = {
                    structure: {
                      colCount: cols.length,
                      rowCount: newRows.length,
                      rows: newRows,
                      colWidths: colWidths,
                      rowHeights: rowHeights,
                    },
                    style: {
                      fontSize: 45,
                      fontWeight: "300",
                      lineHeight: "auto",
                      height: "auto",
                      fontFamily: "Inter",
                    },
                    top,
                    left,
                    width,
                    height,
                    zIndeX: this.state.maxZindex + 1,
                  };
                  this.handleOnNewObject(new SlideObject(SlideTypes.tableObject, null, props));
                  this.setState({ maxZindex: this.state.maxZindex + 1 });
                }
              });
            }
          }
        });
      });
    } else if (item.types.includes("image/png")) {
      item.getType("image/png").then((res) => {
        var reader = new FileReader();
        reader.readAsDataURL(res);
        var base64data;
        reader.onloadend = () => {
          base64data = reader.result;
          this.state.slideObjects.push(
            new SlideObject(SlideTypes.imageObject, base64data, {
              height: "auto",
              zIndeX: this.state.maxZindex + 1,
              staticKey: "so_" + MxIDUseID(),
            }),
          );
          this.setState({ maxZindex: this.state.maxZindex + 1 });
        };
      });
    } else if (item.types.includes("text/html")) {
      if (!item.types.includes("text/plain")) {
        item.getType("text/html").then((res) => {
          res.text().then((data) => {
            let parser = new DOMParser();
            const doc = parser.parseFromString(data, "text/html");
            this.changeDocElementsStyle(doc);
            this.state.slideObjects.push(
              new SlideObject(SlideTypes.htmlObject, doc.children[0].children[1].innerHTML, {
                zIndeX: this.state.maxZindex + 1,
                staticKey: "so_" + MxIDUseID(),
              }),
            );
            this.setState({ maxZindex: this.state.maxZindex + 1 });
          });
        });
      }
    }
  };

  changeDocElementsStyle = (element, shouldChangeCSS) => {
    if (shouldChangeCSS) {
      element.style.width = "100%";
      element.style.height = "100%";
    }
    if (element.children.length > 0) {
      for (let i = 0; i < element.children.length; i++) {
        this.changeDocElementsStyle(
          element.children[i],
          element.nodeName === "BODY" ? true : shouldChangeCSS,
        ); //samo posle body kje se stava style
      }
    }
  };

  handleColorChange = (event) => {
    this.setState({ style: { background: event.target.value } });
  };

  onPanelMouseDown = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetMouse = 1 / window.panelScale;
    let { clientX: startX, clientY: startY } = e;

    startX = (startX - rect.left) * offsetMouse;
    startY = (startY - rect.top) * offsetMouse;

    if (this.state.openContextMenu) {
      this.handleClose(() => {
        this.selectorActive = true;
      });
    } else {
      this.selectorActive = true;
    }

    if (this.state.selectedObject !== "panel" || this.state.selectedObjects.length > 0)
      this.setState({ selectedObject: "panel", selectedObjects: [] });
    this.selectorRef.current.setStyle({
      ...this.selectorRef.current.getStyle(),
      left: startX,
      top: startY,
      display: "block",
    });
    const onMove = (e) => {
      const { clientX, clientY } = e;
      const clientXCalculated = (clientX - rect.left) * offsetMouse;
      const clientYCalculated = (clientY - rect.top) * offsetMouse;
      this.selectorRef.current.setStyle({
        ...this.selectorRef.current.getStyle(),
        width: Math.abs(clientXCalculated - startX),
        height: Math.abs(clientYCalculated - startY),
        left:
          clientXCalculated - startX < 0 ? startX - Math.abs(clientXCalculated - startX) : startX,
        top:
          clientYCalculated - startY < 0 ? startY - Math.abs(clientYCalculated - startY) : startY,
      });
      let directionX = clientXCalculated - startX > 0 ? "left" : "right";
      let directionY = clientYCalculated - startY > 0 ? "top" : "bottom";
      this.handleMouseSelectedObjects(
        clientXCalculated - startX < 0 ? startX - Math.abs(clientXCalculated - startX) : startX,
        clientYCalculated - startY < 0 ? startY - Math.abs(clientYCalculated - startY) : startY,
        Math.abs(clientXCalculated - startX),
        Math.abs(clientYCalculated - startY),
        directionX,
        directionY,
      );
    };
    const onUp = () => {
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      this.selectorRef.current.setStyle({
        ...this.selectorRef.current.getStyle(),
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        display: "none",
      });
      this.selectorActive = false;
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  handleMouseSelectedObjects = (startX, startY, deltaX, deltaY, directionX, directionY) => {
    this.state.slideObjects.forEach((obj) => {
      if (!this.state.selectedObjects.some((o) => o == obj)) {
        if (
          obj.type === SlideTypes.textObject ||
          obj.type === SlideTypes.newLineObject ||
          obj.type === SlideTypes.elbowConnectorObject
        ) {
          if (startY + deltaY > obj.props.top && startY < obj.props.top) {
            // +obj.props.height
            if (startX + deltaX > obj.props.left && startX < obj.props.left + obj.props.width) {
              this.state.selectedObjects.push(obj);
              this.forceUpdate();
            }
          }
        } else {
          if (startY + deltaY > obj.props.top && startY < obj.props.top + obj.props.height) {
            // +obj.props.height
            if (startX + deltaX > obj.props.left && startX < obj.props.left + obj.props.width) {
              this.state.selectedObjects.push(obj);
              this.forceUpdate();
            }
          }
        }
      } else {
        if (
          obj.type === SlideTypes.textObject ||
          obj.type === SlideTypes.newLineObject ||
          obj.type === SlideTypes.elbowConnectorObject
        ) {
          if (startY + deltaY < obj.props.top || startY > obj.props.top) {
            // +obj.props.height
            const newList = this.state.selectedObjects.filter((c) => c != obj);
            this.setState({ selectedObjects: newList });
          } else if (
            startX + deltaX < obj.props.left ||
            startX > obj.props.left + obj.props.width
          ) {
            const newList = this.state.selectedObjects.filter((c) => c != obj);
            this.setState({ selectedObjects: newList });
          }
        } else {
          if (startY + deltaY < obj.props.top || startY > obj.props.top + obj.props.height) {
            // +obj.props.height
            const newList = this.state.selectedObjects.filter((c) => c != obj);
            this.setState({ selectedObjects: newList });
          } else if (
            startX + deltaX < obj.props.left ||
            startX > obj.props.left + obj.props.width
          ) {
            const newList = this.state.selectedObjects.filter((c) => c != obj);
            this.setState({ selectedObjects: newList });
          }
        }
      }
    });
  };

  isSelected = (obj) => {
    if (this.state.selectedObjects.some((o) => o.key === obj.key)) {
      return true;
    } else return false;
  };

  handleOnSave = (shouldMakeScreenshoot = true, callBack = () => {}) => {
    let JsonString = JSON.stringify({
      slideObjects: this.state.slideObjects,
      slideBg: this.state.style.background,
    });

    this.props.saveContent(
      this.props.businessPlan ? this.props.selectedSection.ID : this.props.selectedTemplateID,
      JsonString,
      callBack,
      this.AccountID,
      this.CompanyID,
      this.PitchScenarioID,
      false,
      shouldMakeScreenshoot,
    );
  };

  handleThemeBackgroundUpdate = async (color) => {
    try {
      if (this.props.PitchScenarioInfo) {
        await this.props.changeDeckColor(color);
        await updateThemeBackgroundWithPitchScenario(this.props.PitchScenarioID, color);
      } else {
        await updateThemeBackgroundForBusinessPlan(global.Modeliks.BusinessPlanConfig, color);
      }
    } catch (error) {
      console.error(error);
    }

    if (!this.props.selectedTemplateColor) {
      this.setState((prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            background: color,
          },
        };
      });
    } else {
      this.forceUpdate();
    }
  };

  handleAddNewSection = (name, callBack) => {
    let JsonString = JSON.stringify({
      slideObjects: this.state.slideObjects,
      slideBg: this.state.style.background,
    });

    this.props.saveNewSection(this.props.selectedTemplateID, JsonString, name, callBack);
  };

  handleOnApplyTemplate = (templateObj) => {
    const newObjects = [];
    const template = JSON.parse(templateObj);
    template.slideObjects.forEach((obj) =>
      newObjects.push(new SlideObject(obj.type, obj.value, obj.props)),
    );

    this.setState({ slideObjects: [] }, () => {
      this.setState({ slideObjects: newObjects });
    });

    // const savedData = JSON.parse(templateObj);
    // savedData.slideObjects.forEach(obj => this.state.objects.push(new SlideObject(obj.type, obj.value, obj.props)));
    this.setState({ style: { background: templateObj.slideBg.toString() } });
  };

  handleRemoveSlideObject = (item, shouldPushChange = true) => {
    // if (this.isGrouped(item)) {
    //     this.ungroupObjects(item.props.groupKey, false)
    // }
    if (item === "panel") {
      return;
    }
    if (shouldPushChange) {
      const itemIndex = this.state.slideObjects.findIndex((obj) => obj.key === item.key);
      global.slideHistory.pushNewChange(
        this.slideObjectStrategy.getHistoryItem(item, itemIndex),
        "delete",
        null,
        null,
      );
    }
    const newList = this.state.slideObjects.filter((c) => c != item);
    this.setState({ slideObjects: [], selectedObject: "panel" }, () => {
      this.setState({ slideObjects: newList });
    });
    this.removeObjectBoundaries(item);
  };

  handleRemoveSelectedObject = () => {};

  onObjectClickHandler = (obj) => {
    //this.selectorRef.current.setVisibleHeader(false)
    this.setState({ selectedObject: obj, selectedObjects: [] });
  };

  handleMultipleDelete = (objects = [], shouldPushChange = true) => {
    let newList = [];
    let deletedObjects = [];
    let o = true;
    if (objects.length === 0) {
      this.state.slideObjects.forEach((obj) => {
        o = true;
        for (let i = 0; i < this.state.selectedObjects.length; i++) {
          if (obj === this.state.selectedObjects[i]) {
            o = false;
            deletedObjects.push(obj);
            this.removeObjectBoundaries(obj);
            break;
          }
        }
        o && newList.push(obj);
      });
    } else {
      this.state.slideObjects.forEach((obj) => {
        o = true;
        for (let i = 0; i < objects.length; i++) {
          if (obj === objects[i]) {
            o = false;
            deletedObjects.push(obj);
            this.removeObjectBoundaries(obj);
            break;
          }
        }
        o && newList.push(obj);
      });
    }

    if (shouldPushChange) {
      global.slideHistory.pushNewChange(deletedObjects, "multiple delete", null, null);
    }

    this.setState({ slideObjects: [] }, () => {
      this.setState({ slideObjects: newList, selectedObjects: [] });
    });
  };

  removeObjectBoundaries = (slideObj) => {
    //remove if boundaries exist in cur boundaries
    let newBoundriesX = [];
    let newBoundriesY = [];
    const b_x = this.boundries.x;
    const b_y = this.boundries.y;
    for (let i = 0; i < b_x.length; i++) {
      if (i > 3) {
        if (slideObj && !(slideObj.key == b_x[i].objKey)) newBoundriesX.push(b_x[i]);
      } else newBoundriesX.push(b_x[i]);
    }
    for (let i = 0; i < b_y.length; i++) {
      if (i > 3) {
        if (slideObj && !(slideObj.key == b_y[i].objKey)) newBoundriesY.push(b_y[i]);
      } else newBoundriesY.push(b_y[i]);
    }
    this.boundries.x = newBoundriesX;
    this.boundries.y = newBoundriesY;
  };
  // onCopy = (obj) => {
  //     obj.select();
  //     document.execCommand('copy');
  // }

  recalculateSlideObjectBoundaries(slideObj) {
    //add 2 x X
    //add 2 x Y
    this.removeObjectBoundaries(slideObj);

    this.boundries.x.push(
      this.buildBX(slideObj.props.left, "activeVertical", slideObj.props.left, slideObj.key),
    );
    this.boundries.x.push(
      this.buildBX(
        slideObj.props.width + slideObj.props.left,
        "activeVertical",
        slideObj.props.width + slideObj.props.left,
        slideObj.key,
      ),
    );
    this.boundries.y.push(
      this.buildBY(slideObj.props.top, "activeHorizontal", slideObj.props.top, slideObj.key),
    );
    if (slideObj.props.height != "auto")
      this.boundries.y.push(
        this.buildBY(
          slideObj.props.height + slideObj.props.top,
          "activeHorizontal",
          slideObj.props.height + slideObj.props.top,
          slideObj.key,
        ),
      );
  }

  sideMostObjects = () => {
    let leftMostObj = { props: { left: 1280 } };
    let topMostObj = { props: { top: 720 } };
    let rightMostObj = { props: { left: 0, width: 0 } };
    let bottomMostObj = { props: { top: 0, height: 0 } };

    this.state.selectedObjects.forEach((obj) => {
      if (obj.props.left < leftMostObj.props.left) {
        leftMostObj = obj;
      }
      if (obj.props.top < topMostObj.props.top) {
        topMostObj = obj;
      }
      if (obj.props.left + obj.props.width > rightMostObj.props.left + rightMostObj.props.width) {
        rightMostObj = obj;
      }
      if (obj.props.top + obj.props.height > bottomMostObj.props.top + bottomMostObj.props.height) {
        bottomMostObj = obj;
      }
    });

    return {
      leftMostObj: leftMostObj,
      topMostObj: topMostObj,
      rightMostObj: rightMostObj,
      bottomMostObj: bottomMostObj,
    };
  };

  leftMostObj = (objs) => {
    let myObj = {
      props: {
        left: 1280,
      },
    };
    objs.forEach((obj) => {
      if (obj.props.left < myObj.props.left) myObj = obj;
    });

    return myObj;
  };

  rightMostObj = (objs) => {
    let myObj = {
      props: {
        left: 0,
        width: 0,
      },
    };
    objs.forEach((obj) => {
      if (obj.props.left + obj.props.width > myObj.props.left + myObj.props.width) myObj = obj;
    });

    return myObj;
  };

  topMostObj = (objs) => {
    let myObj = {
      props: {
        top: 720,
      },
    };
    objs.forEach((obj) => {
      if (obj.props.top < myObj.props.top) myObj = obj;
    });

    return myObj;
  };

  bottomMostObj = (objs) => {
    let myObj = {
      props: {
        top: 0,
        height: 0,
      },
    };
    objs.forEach((obj) => {
      if (obj.props.top + obj.props.height > myObj.props.top + myObj.props.height) myObj = obj;
    });

    return myObj;
  };

  centerOnPage = (horizontally = false, vertically = false) => {
    this.state.selectedObjects.forEach((c) => {
      let height = c.props.height;
      if (isNaN(height)) {
        height = 0;
      }
      c.updatePosition(
        horizontally ? 640 - c.props.width / 2 : c.props.left,
        vertically ? 360 - height / 2 : c.props.top,
      );
    });
  };

  updatePosition = (left, top) => {
    let sideMostObjects = this.sideMostObjects();
    if (top === null) {
      switch (left) {
        case 0:
          left = sideMostObjects.leftMostObj.props.left;
          for (let i = 0; i <= this.state.selectedObjects.length - 1; i++) {
            this.state.selectedObjects[i].updatePosition(
              left,
              this.state.selectedObjects[i].props.top,
            );
          }
          break;
        case 960:
          this.state.selectedObjects.sort(this.compareByLeftMiddle);
          let firstObjsMiddle = this.getObjMiddle(this.state.selectedObjects[0], "left", "width");
          let middle =
            (this.getObjMiddle(
              this.state.selectedObjects[this.state.selectedObjects.length - 1],
              "left",
              "width",
            ) -
              firstObjsMiddle) /
              2 +
            firstObjsMiddle;
          this.state.selectedObjects.forEach((obj) => {
            obj.updatePosition(middle - obj.props.width / 2, obj.props.top);
          });
          break;
        case 1280:
          let right =
            sideMostObjects.rightMostObj.props.left + sideMostObjects.rightMostObj.props.width;
          this.state.selectedObjects.forEach((obj) => {
            obj.updatePosition(right - obj.props.width, obj.props.top);
          });
          break;
      }
    } else if (left === null) {
      switch (top) {
        case 0:
          top = sideMostObjects.topMostObj.props.top;
          this.state.selectedObjects.forEach((obj) => {
            obj.updatePosition(obj.props.left, top);
          });
          break;
        case 540:
          let firstObjsMiddle = this.getObjMiddle(sideMostObjects.topMostObj, "top", "height");
          let middle =
            (this.getObjMiddle(sideMostObjects.bottomMostObj, "top", "height") - firstObjsMiddle) /
              2 +
            firstObjsMiddle;
          this.state.selectedObjects.forEach((obj) => {
            obj.updatePosition(obj.props.left, middle - obj.props.height / 2);
          });
          break;
        case 720:
          let bottom =
            sideMostObjects.bottomMostObj.props.top + sideMostObjects.bottomMostObj.props.height;
          this.state.selectedObjects.forEach((obj) => {
            obj.updatePosition(obj.props.left, bottom - obj.props.height);
          });
          break;
      }
    }
  };
  compareByTopMiddle = (obj1, obj2) => {
    if (obj1.props.top + obj1.props.height / 2 > obj2.props.top + obj2.props.height / 2) {
      return 1;
    } else if (obj1.props.top + obj1.props.height / 2 < obj2.props.top + obj2.props.height / 2) {
      return -1;
    } else return 0;
  };

  compareByLeftMiddle = (obj1, obj2) => {
    if (obj1.props.left + obj1.props.width / 2 > obj2.props.left + obj2.props.width / 2) {
      return 1;
    } else if (obj1.props.left + obj1.props.width / 2 < obj2.props.left + obj2.props.width / 2) {
      return -1;
    } else return 0;
  };

  compareByTop = (obj1, obj2) => {
    if (obj1.props.top > obj2.props.top) {
      return 1;
    } else if (obj1.props.top < obj2.props.top) {
      return -1;
    } else return 0;
  };
  compareByLeft = (obj1, obj2) => {
    if (obj1.props.left > obj2.props.left) {
      return 1;
    } else if (obj1.props.left < obj2.props.left) {
      return -1;
    } else return 0;
  };

  sortByZindex = (obj1, obj2) => {
    if (obj1.props.zIndeX >= obj2.props.zIndeX) {
      return 1;
    } else if (obj1.props.zIndeX < obj2.props.zIndeX) {
      return -1;
    } else return 0;
  };

  bringForword = () => {
    this.state.slideObjects.sort(this.sortByZindex);
    this.state.slideObjects.forEach((obj, index) => {
      if (
        JSON.stringify(obj) == JSON.stringify(this.state.selectedObject) &&
        index !== this.state.slideObjects.length - 1
      ) {
        if (index === this.state.slideObjects.length - 2) {
          let zIndex = this.state.slideObjects[index + 1].props.zIndeX;
          this.state.selectedObject.props.zIndeX = zIndex + 1;
          this.setState({ maxZindex: zIndex + 1 });
        } else {
          let zIndex = this.state.slideObjects[index + 1].props.zIndeX;
          this.state.selectedObject.props.zIndeX = zIndex + 1;
          this.forceUpdate();
        }
      }
    });
  };

  bringBackward = () => {
    this.state.slideObjects.sort(this.sortByZindex);
    this.state.slideObjects.forEach((obj, index) => {
      if (JSON.stringify(obj) == JSON.stringify(this.state.selectedObject) && index !== 0) {
        if (index === 1) {
          let zIndex = this.state.slideObjects[index - 1].props.zIndeX;
          this.state.selectedObject.props.zIndeX = zIndex - 1;
          this.setState({ minZindex: zIndex - 1 });
        } else {
          let zIndex = this.state.slideObjects[index - 1].props.zIndeX;
          this.state.selectedObject.props.zIndeX = zIndex - 1;
          this.forceUpdate();
        }
      }
    });
  };

  sentToBack = () => {
    this.state.slideObjects.sort(this.sortByZindex);
    let lastObjZindex = this.state.slideObjects[0].props.zIndeX;
    if (this.state.selectedObjects.length > 1) {
      this.state.selectedObjects.forEach((c) => {
        c.props.zIndeX = lastObjZindex - 1;
      });
    } else {
      this.state.selectedObject.props.zIndeX = lastObjZindex - 1;
    }
    this.setState({ minZindex: lastObjZindex - 1 });
  };

  sentToFront = () => {
    this.state.slideObjects.sort(this.sortByZindex);
    let lastObjZindex = this.state.slideObjects[this.state.slideObjects.length - 1].props.zIndeX;
    if (this.state.selectedObjects.length > 1) {
      this.state.selectedObjects.forEach((c) => {
        c.props.zIndeX = lastObjZindex + 1;
      });
    } else {
      this.state.selectedObject.props.zIndeX = lastObjZindex + 1;
    }
    this.setState({ maxZindex: lastObjZindex + 1 });
  };

  updateTemplateColor = (color) => {
    this.props.changeTemplateColor(color, () => {
      this.setState((prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            background: color,
          },
        };
      });
    });
  };

  getObjMiddle = (obj, startPoint, spanning) => {
    return obj.props[startPoint] + obj.props[spanning] / 2;
  };

  distributeHorizontally = () => {
    this.state.selectedObjects.sort(this.compareByLeftMiddle);
    let firstObjsMiddle = this.getObjMiddle(this.state.selectedObjects[0], "left", "width");
    let horizontalDifference =
      this.getObjMiddle(
        this.state.selectedObjects[this.state.selectedObjects.length - 1],
        "left",
        "width",
      ) - firstObjsMiddle;
    let increment = horizontalDifference / (this.state.selectedObjects.length - 1);
    for (let i = 1; i < this.state.selectedObjects.length - 1; i++) {
      this.state.selectedObjects[i].updatePosition(
        firstObjsMiddle + (increment * i - this.state.selectedObjects[i].props.width / 2),
        this.state.selectedObjects[i].props.top,
      );
    }
  };
  distributeVertically = () => {
    this.state.selectedObjects.sort(this.compareByTopMiddle);
    let firstObjsMiddle = this.getObjMiddle(this.state.selectedObjects[0], "top", "height");
    let horizontalDifference =
      this.getObjMiddle(
        this.state.selectedObjects[this.state.selectedObjects.length - 1],
        "top",
        "height",
      ) - firstObjsMiddle;
    let increment = horizontalDifference / (this.state.selectedObjects.length - 1);
    for (let i = 1; i < this.state.selectedObjects.length - 1; i++) {
      this.state.selectedObjects[i].updatePosition(
        this.state.selectedObjects[i].props.left,
        firstObjsMiddle + (increment * i - this.state.selectedObjects[i].props.height / 2),
      );
    }
  };
  resetPositioning = () => {
    this.setState({ left: null, top: null });
  };

  handleUndo() {
    global.slideHistory.undo(
      this.updateComponent,
      this.undoPushObj,
      this.handleRemoveSlideObject,
      this.handlePositionUndoRedo,
      () => this.deleteObj(),
      () => this.insertObj(),
      this.handleResizeUndoRedo,
      this.handleGroupChangeUndoRedo,
    );
    this.setupUndoRedoActions();
  }

  handleRedo() {
    global.slideHistory.redo(
      this.updateComponent,
      this.redoDeleteObj,
      this.redoInsert,
      this.handlePositionUndoRedo,
      () => this.deleteObj(),
      () => this.insertObj(),
      this.handleResizeUndoRedo,
      this.handleGroupChangeUndoRedo,
    );
    this.setupUndoRedoActions();
  }

  setupKeyHandlers() {
    this.keyEventsHandler.addKeyHandler({ keyCode: KeyboardEventKeys.ESCAPE }, (e) => {
      this.state.selectedObject = null;
      this.state.selectedObjects = [];
      this.forceUpdate();
    });

    this.keyEventsHandler.addKeyHandler(
      { keyCode: [KeyboardEventKeys.BACKSPACE, KeyboardEventKeys.DELETE] },
      (e) => {
        if (!this.state.selectedObject && this.state.selectedObjects.length === 0) {
          return;
        }
        if (this.state.selectedObjects.length > 0) {
          this.handleMultipleDelete();
        } else {
          this.handleRemoveSlideObject(this.state.selectedObject);
        }
      },
    );

    this.keyEventsHandler.addKeyHandler(
      { ctrlKey: true, keyCode: KeyboardEventKeys.KEY_G }, // Ctrl + G
      (e) => {
        this.groupObjects();
      },
    );

    this.keyEventsHandler.addKeyHandler(
      { ctrlKey: true, keyCode: KeyboardEventKeys.KEY_Z }, // Ctrl + Z
      (e) => {
        this.handleUndo();
      },
    );

    this.keyEventsHandler.addKeyHandler(
      { ctrlKey: true, keyCode: KeyboardEventKeys.KEY_Y }, // Ctrl + Y
      (e) => {
        this.handleRedo();
      },
    );

    this.keyEventsHandler.addKeyHandler(
      { ctrlKey: true, keyCode: KeyboardEventKeys.KEY_X }, // Ctrl + X
      (e) => {
        e.preventDefault();
        this.cutObjects();
      },
    );

    this.keyEventsHandler.addKeyHandler(
      { ctrlKey: true, keyCode: KeyboardEventKeys.KEY_C }, // Ctrl + C
      (e) => {
        e.preventDefault();
        this.copyObjects();
      },
    );

    this.keyEventsHandler.addKeyHandler(
      { ctrlKey: true, keyCode: KeyboardEventKeys.KEY_D }, // Ctrl + D
      (e) => {
        if (this.state.selectedObject || this.state.selectedObjects) {
          this.handleDuplicateObject();
        }
      },
    );

    this.keyEventsHandler.addKeyHandler({ keyCode: KeyboardEventKeys.UP_ARROW }, (e) => {
      if (
        !this.state.selectedObjects.length &&
        (!this.state.selectedObject || this.state.selectedObject === "panel")
      ) {
        this.props.selectPrevSlide();
      }
    });

    this.keyEventsHandler.addKeyHandler({ keyCode: KeyboardEventKeys.DOWN_ARROW }, (e) => {
      if (
        !this.state.selectedObjects.length &&
        (!this.state.selectedObject || this.state.selectedObject === "panel")
      ) {
        this.props.selectNextSlide();
      }
    });
  }

  deleteObj = (obj) => {
    this.handleRemoveSlideObject(obj, false);
  };

  insertObj = (obj) => {
    this.state.slideObjects.push(obj);
  };

  undoPushObj = (obj, type) => {
    if (type === "delete") {
      this.setState((prevState) => ({
        slideObjects: addItemAtIndex(prevState.slideObjects, obj, obj.itemIndex),
      }));
    } else if (type === "multiple delete") {
      obj.forEach((o) => {
        this.state.slideObjects.push(o);
      });
    }
  };

  handlePositionUndoRedo = (key, top, left) => {
    let slideObject = this.state.slideObjects.filter((c) => c.key === key)[0];
    slideObject.props.top = top;
    slideObject.props.left = left;
    slideObject.changeTopLeft(top, left);
  };

  handleResizeUndoRedo = (key, width, height, rotateAngle) => {
    let slideObject = this.state.slideObjects.filter((c) => c.key === key)[0];
    slideObject.props.width = width;
    slideObject.props.height = height;
    slideObject.props.rotateAngle = rotateAngle;
    slideObject.changeWidthHeight(width, height, rotateAngle);
  };

  handleGroupChangeUndoRedo = (objsArr, change) => {
    objsArr.forEach((obj, index) => {
      obj.obj.changeGroupProps(change[index]);

      // if (obj.changeType == changeTypes.props) {
      //     // obj.
      // } else if (obj.changeType == changeTypes.position) {
      //     if (obj.obj.type === SlideTypes.GroupObject) {
      //         obj.obj.changeGroupProps(change[index])
      //     } else {
      //         obj.obj.changeTopLeft(change[index].top, change[index].left, false)
      //     }
      // }
    });
  };

  redoDeleteObj = (obj, type) => {
    if (type === "delete") {
      this.handleRemoveSlideObject(obj, false);
    } else if (type === "multiple delete") {
      this.handleMultipleDelete(obj, false);
    }
  };

  redoInsert = (obj) => {
    if (this.isGrouped(obj)) {
      this.groupObjects(obj.value, obj.props);
    }
    this.state.slideObjects.push(obj);
  };

  handleCopy = () => {
    this.hasCopiedObjects = true;

    if (this.state.selectedObjects.length > 0) {
      this.copiedObject = null;
      this.copiedObjects = this.state.selectedObjects;
    } else if (this.state.selectedObject) {
      this.copiedObjects = null;
      this.copiedObject = this.state.selectedObject;
    }
  };

  handleDuplicateObject = (obj = null) => {
    if (this.state.selectedObjects.length > 0) {
      this.copiedObject = null;
      this.copiedObjects = [...this.state.selectedObjects];
    } else if (this.state.selectedObject) {
      this.copiedObjects = null;
      this.copiedObject = { ...this.state.selectedObject };
    }

    if (this.copiedObjects != null) {
      let objectsToBeSelected = [];
      this.copiedObjects.forEach((obj) => {
        if (obj.type !== SlideTypes.GroupObject) {
          let props = JSON.parse(JSON.stringify(obj.props));
          const nObj = new SlideObject(obj.type, obj.value, {
            ...props,
            left: obj.props.left + 30,
            top: obj.props.top + 30,
          });
          objectsToBeSelected.push(nObj);
          this.handleOnNewObject(nObj);
        } else {
          this.pasteGroupObject(obj, false);
        }
      });
      this.copiedObjects = null;
      this.setState({ selectedObjects: objectsToBeSelected, selectedObject: null });
    } else {
      if (this.copiedObject.type !== SlideTypes.GroupObject) {
        let props = JSON.parse(JSON.stringify(this.copiedObject.props));
        const nObj = new SlideObject(this.copiedObject.type, this.copiedObject.value, {
          ...props,
          left: this.copiedObject.props.left + 30,
          top: this.copiedObject.props.top + 30,
        });
        this.handleOnNewObject(nObj);
        this.copiedObject = null;
      } else {
        this.pasteGroupObject(this.copiedObject, false);
      }
    }
  };

  // basically handles multiple objects dragEnd
  handleDragEnd = async () => {
    let selectedObjects = [];
    this.state.selectedObjects.forEach((obj) => {
      const { newChange, prevChange } = obj.moveEnd(true);

      if (newChange === undefined || prevChange === undefined) {
        return;
      }

      selectedObjects.push({
        ...obj,
        newChange,
        prevChange,
      });
    });

    // move was already executed in obj.moveEnd(true) function and it will run the positioning again
    // here we just handle the undo/redo execution at the moment
    const moveSelectedObjectsCommand = new MoveSelectedObjects({
      actionBackward: this.handlePositionUndoRedo.bind(this),
      actionForward: this.handlePositionUndoRedo.bind(this),
      objects: selectedObjects,
    });

    await this.context.commandHistoryManager.executeCommand(moveSelectedObjectsCommand);
    this.context.addToGlobalSlideHistory();
  };

  handleDrag = (deltaX, deltaY) => {
    this.state.selectedObjects.forEach((obj) => {
      obj.move(deltaX, deltaY, true);
    });
  };

  onDragStart = () => {
    this.state.selectedObjects.forEach((obj) => {
      obj.moveStart();
    });
  };

  handleActiveFormatPainter = (style = {}) => {
    if (this.state.activeFormatPainter) {
      this.setState({ activeFormatPainter: false });
    } else {
      this.setState({ activeFormatPainter: true, formatPainterStyle: style });
    }
  };

  handleNewScribble = () => {
    this.setState({ scribble: true });
  };
  createNewLines = (arr) => {
    arr.forEach((nObj) => {
      this.state.slideObjects.push(nObj);
      nObj.props.zIndeX = this.state.maxZindex + 1;
      this.state.maxZindex++;
      this.recalculateSlideObjectBoundaries(nObj);
    });
    this.setState({ scribble: false });
  };

  updateComponent = () => {
    this.forceUpdate();
  };

  changeObjectsPosition = (slideObjects, _top = null, _left = null, objChangeArgs = null) => {
    const changes = [];
    slideObjects.forEach((obj) => {
      let prevChanges = { top: obj.props.top, left: obj.props.left };
      if (objChangeArgs?.length && objChangeArgs[0] == obj) {
        prevChanges = objChangeArgs[3];
      }
      const top = obj.props.top + _top;
      obj.props.top = top;
      const left = obj.props.left + _left;
      obj.props.left = left;
      changes.push([obj, "position", { top, left }, prevChanges]);
      obj.changeTopLeft(top, left, false);
    });
    return changes;
  };

  changeObjectsRatio = (slideObjects, width, height) => {
    const changes = [];
    slideObjects.forEach((obj) => {
      const newProps = {
        widthRatio: width / obj.props.width,
        heightRatio: height / obj.props.height,
        topRatio: height / obj.props.top,
        leftRatio: width / obj.props.left,
      };
      const oldProps = {
        widthRatio: obj.props.widthRatio,
        heightRatio: obj.props.heightRatio,
        topRatio: obj.props.topRatio,
        leftRatio: obj.props.leftRatio,
      };
      obj.updateProps(newProps, "props", false);
      changes.push([obj, "props", newProps, oldProps]);
    });
  };

  recalculateObjectsAgainstRatio = (groupKey, groupChange) => {
    let groupObject = this.state.slideObjects.filter(
      (c) => c.type === SlideTypes.GroupObject && c.props.groupKey === groupKey,
    )[0];
    let groupedObjects = this.state.slideObjects.filter(
      (c) => c.props.groupKey === groupKey && c.type !== SlideTypes.GroupObject,
    );
    let changes = [groupChange];

    groupedObjects.forEach((obj) => {
      let { widthRatio, heightRatio, topRatio, leftRatio, width, height, left, top } = obj.props;
      let newWidth = groupObject.props.width / widthRatio;
      let newHeight = groupObject.props.height / heightRatio;
      let newTop = groupObject.props.height / topRatio;
      let newLeft = groupObject.props.width / leftRatio;
      const newProps = { width: newWidth, height: newHeight, left: newLeft, top: newTop };

      obj.changeGroupProps(newProps);
      changes.push([obj, "props", newProps, { width, height, left, top }]);
    });

    global.slideHistory.pushNewChange(
      groupObject,
      changeTypes.multiple_group,
      changes.map((c) => c[2]),
      changes.map((c) => c[3]),
      undefined,
      changes.map((c) => {
        return { obj: c[0], changeType: c[1] };
      }),
    );
  };

  recalculateGroupObject = (groupKey, objChangeArgs) => {
    console.log(objChangeArgs);
    let changes = [objChangeArgs]; //false ? [objChangeArgs] : [];//
    // debugger

    let groupObject = this.state.slideObjects.filter(
      (c) => c.type === SlideTypes.GroupObject && c.props.groupKey === groupKey,
    )[0];
    let groupedObjects = this.state.slideObjects.filter(
      (c) => c.props.groupKey === groupKey && c.type !== SlideTypes.GroupObject,
    );

    let topMostObj = this.topMostObj(groupedObjects);
    let leftMostObj = this.leftMostObj(groupedObjects);
    let rightMostObj = this.rightMostObj(groupedObjects);
    let bottomMostObj = this.bottomMostObj(groupedObjects);

    let oldTop = groupObject.props.top;
    let oldLeft = groupObject.props.left;
    let newTop = topMostObj.props.top + groupObject.props.top;
    let newLeft = leftMostObj.props.left + groupObject.props.left;

    let objectsTop = oldTop - newTop;
    let objectsLeft = oldLeft - newLeft;

    // groupObject.changeTopLeft(newTop, newLeft);
    changes.push(
      ...this.changeObjectsPosition(groupedObjects, objectsTop, objectsLeft, objChangeArgs),
    );
    let width = rightMostObj.props.left + rightMostObj.props.width;
    let height =
      bottomMostObj.props.top + (bottomMostObj.props.height ? bottomMostObj.props.height : 0);

    // groupObject.changeWidthHeight(width, height, groupObject.props.rotateAngle, true);
    changes.push([
      groupObject,
      "position",
      { top: newTop, left: newLeft, width, height },
      {
        top: oldTop,
        left: oldLeft,
        width: groupObject.props.width,
        height: groupObject.props.height,
      },
    ]);
    groupObject.changeGroupProps({ width, height, top: newTop, left: newLeft });

    // console.log()

    changes.push(
      ...this.changeObjectsRatio(groupedObjects, groupObject.props.width, groupObject.props.height),
    );

    global.slideHistory.pushNewChange(
      groupObject,
      changeTypes.multiple_group,
      changes.map((c) => c[2]),
      changes.map((c) => c[3]),
      undefined,
      changes.map((c) => {
        return { obj: c[0], changeType: c[1] };
      }),
    );
  };

  canGroupObjects = () =>
    !this.state.selectedObjects.find((c) => c.type === SlideTypes.tableObject);

  groupObjects = (selectedObjects = this.state.selectedObjects, object = null) => {
    let rightObj = this.rightMostObj(selectedObjects);
    let bottomObj = this.bottomMostObj(selectedObjects);
    let top = object?.top ?? this.topMostObj(selectedObjects).props.top;
    let left = object?.left ?? this.leftMostObj(selectedObjects).props.left;

    const key = object?.groupKey ?? "go_" + new Date().getTime();

    let groupObjWidth = object?.width ?? rightObj.props.left + rightObj.props.width - left;
    let groupObjHeight =
      object?.height ??
      bottomObj.props.top + (bottomObj.props.height ? bottomObj.props.height : 0) - top;

    let obj = {
      top: top,
      left: left,
      width: groupObjWidth,
      height: groupObjHeight,
      groupKey: key,
    };

    selectedObjects.forEach((obj) => {
      obj.updateProps(
        {
          groupKey: key,
          top: obj.props.top - top,
          left: obj.props.left - left,
          widthRatio: groupObjWidth / obj.props.width,
          heightRatio: groupObjHeight / (obj.props.height ?? 1),
          topRatio: groupObjHeight / (obj.props.top - top),
          leftRatio: groupObjWidth / (obj.props.left - left),
        },
        "props",
        false,
      );
    });

    !object &&
      this.handleOnNewObject(new SlideObject(SlideTypes.GroupObject, selectedObjects, obj));
  };

  onObjectClick = (e, obj) => {
    if (!this.didMoveHappen) {
      if (e && e.button != 2) {
        if (!this.keyEventsHandler.keyCtrl && !this.keyEventsHandler.shiftKey) {
          if (
            !this.props.preview &&
            !this.props.publishMode &&
            !this.props.disableEdit &&
            obj != this.state.selectedObject
          ) {
            this.state.selectedObject = null;
            this.setState({
              selectedObject: obj,
              selectedObjects: [],
            });
          }
        } else {
          if (this.state.selectedObject && this.state.selectedObject !== "panel") {
            this.state.selectedObjects = [this.state.selectedObject, obj];
            this.state.selectedObject = null;
            this.setState({ selectedObject: null });
          } else {
            if (this.state.selectedObjects.indexOf(obj) === -1) {
              this.state.selectedObjects.push(obj);
              this.forceUpdate();
            }
          }
        }
      }
    } else {
      this.didMoveHappen = false;
    }
  };

  cutObjects = () => {
    if (this.state.selectedObject && this.state.selectedObjects.length === 0) {
      let obj = { cutObject: this.state.selectedObject };
      this.handleRemoveSlideObject(this.state.selectedObject);
      navigator.clipboard.writeText(JSON.stringify(obj));
    } else if (this.state.selectedObjects.length > 0) {
      let obj = { cutObjects: this.state.selectedObjects };
      this.handleMultipleDelete();
      navigator.clipboard.writeText(JSON.stringify(obj));
    }
  };

  copyObjects = () => {
    this.props.setTimesPasted(0);
    if (this.state.selectedObject && this.state.selectedObjects.length === 0) {
      let obj = { copyObject: this.state.selectedObject };
      navigator.clipboard.writeText(JSON.stringify(obj));
      this.props.setShouldPasteInPlace(false);
    } else if (this.state.selectedObjects.length > 0) {
      let obj = { copyObjects: this.state.selectedObjects };
      navigator.clipboard.writeText(JSON.stringify(obj));
      this.props.setShouldPasteInPlace(false);
    }
  };

  onLoad = (e) => {
    const height = e.target.naturalHeight;
    const width = e.target.naturalWidth;
    let ratio = width / height;

    if (height > width) {
      this.logoConatiner.current.style.height = "90px";
      this.logoConatiner.current.style.width = 90 * ratio + "px";
    } else {
      this.logoConatiner.current.style.height = (90 * 1) / ratio + "px";
      this.logoConatiner.current.style.width = "90px";
    }
  };

  returnPageNumber = () => {
    if (this.props.businessPlan) {
      return this.props.pageNumber;
    } else {
      return (
        this.props.SlideSections &&
        this.props.SlideSections.length &&
        this.props.selectedSection &&
        this.props.SlideSections.indexOf(this.props.selectedSection) + 1
      );
    }
  };

  returnHeader = () => {
    if (this.props.businessPlan) {
      if (!this.props.publishMode) {
        return (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              paddingLeft: "49px",
              paddingRight: "49px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "flex-end",
                borderBottom: "1px solid black",
                paddingBottom: "5px",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  color: "#585858",
                  fontFamily: "Inter",
                  fontSize: "12pt",
                }}
              >
                {global.Modeliks.CompanyInfo.CompanyName}
              </div>
              {global.Modeliks.BusinessPlanConfig.Logo && (
                <LogoComponent notAbsolute businessPlan />
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              paddingLeft: "49px",
              paddingRight: "49px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "flex-end",
                borderBottom: "1px solid black",
                paddingBottom: "5px",
              }}
            >
              <div
                style={{
                  color: "#585858",
                  fontFamily: "Inter",
                  fontSize: "12pt",
                }}
              >
                {this.props.slideConfig.CompanyName}
              </div>
              {this.props.slideConfig && this.props.slideConfig.Logo && this.state.base64str ? (
                <div
                  style={{
                    zIndex: this.state.maxZindex + 10,
                  }}
                  ref={this.logoConatiner}
                >
                  {
                    <img
                      src={`data:image/png;base64,${this.state.base64str}`}
                      onLoad={this.onLoad}
                      style={{ width: "100%", height: "100%" }}
                    />
                  }
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }
    } else {
      return ((!this.props.preview || this.props.showLogo) &&
        this.props.PitchScenarioInfo &&
        !this.props.publishMode &&
        this.props.PitchScenarioInfo.Logo &&
        this.returnPageNumber() > 1) ||
        (this.props.PitchScenarioInfo &&
          this.props.PitchScenarioInfo.Logo &&
          this.props.showLogo) ? (
        <>
          <LogoComponent />
        </>
      ) : this.props.slideConfig && this.props.slideConfig.Logo && this.state.base64str ? (
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            zIndex: 999999,
          }}
          ref={this.logoConatiner}
        >
          {
            <img
              src={`data:image/png;base64,${this.state.base64str}`}
              onLoad={this.onLoad}
              style={{ width: "100%", height: "100%" }}
            />
          }
        </div>
      ) : (
        ""
      );
    }
  };

  returnFooter = (pageCounter) => {
    if (this.props.businessPlan) {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            paddingLeft: "49px",
            paddingRight: "49px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              borderTop: "1px solid black",
              minHeight: "18px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {
              <div
                style={{
                  color: "#585858",
                  fontFamily: "Inter",
                  fontSize: "12pt",
                  zIndex: this.state.maxZindex + 10,
                }}
              >
                {global.Modeliks.BusinessPlanConfig.FooterText}
              </div>
            }

            {pageCounter && !this.props.publishMode && this.props.pageNumber ? (
              <div
                style={{
                  color: "#585858",
                  fontFamily: "Inter",
                  fontSize: "12pt",
                  zIndex: this.state.maxZindex + 10,
                }}
              >
                {this.returnPageNumber()}
              </div>
            ) : pageCounter &&
              this.props.slideConfig &&
              this.props.slideConfig.Counter &&
              this.props.pageNumber ? (
              <div
                style={{
                  color: "#585858",
                  fontFamily: "Inter",
                  fontSize: "12pt",
                  zIndex: this.state.maxZindex + 10,
                }}
              >
                {this.props.pageNumber}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      return (
        <>
          {pageCounter && !this.props.publishMode && this.props.pageNumber ? (
            <div
              style={{
                position: "absolute",
                color: "#585858",
                fontFamily: "Inter",
                fontSize: "12pt",
                right: this.props.businessPlan ? 250 : 30,
                bottom: this.props.businessPlan ? 250 : 30,
                zIndex: 999999,
              }}
            >
              {this.returnPageNumber()}
            </div>
          ) : pageCounter &&
            this.props.slideConfig &&
            this.props.slideConfig.Counter &&
            this.props.pageNumber ? (
            <div
              style={{
                position: "absolute",
                right: this.props.businessPlan ? 250 : 30,
                bottom: this.props.businessPlan ? 250 : 30,
                color: "#585858",
                fontFamily: "Inter",
                fontSize: "12pt",
                zIndex: 999999,
              }}
            >
              {this.props.pageNumber}
            </div>
          ) : (
            ""
          )}

          {this.props.PitchScenarioInfo &&
          !this.props.publishMode &&
          this.props.PitchScenarioInfo.FooterText ? (
            <div
              style={{
                position: "absolute",
                left: this.props.businessPlan ? 250 : 30,
                bottom: this.props.businessPlan ? 250 : 30,
                right: this.props.businessPlan ? 300 : 30,
                color: "#585858",
                fontFamily: "Inter",
                fontSize: "12pt",
                overflow: "hidden",
                zIndex: 999999,
              }}
            >
              {this.props.PitchScenarioInfo.FooterText}
            </div>
          ) : this.props.slideConfig && this.props.slideConfig.FooterText ? (
            <div
              style={{
                position: "absolute",
                left: this.props.businessPlan ? 250 : 30,
                bottom: this.props.businessPlan ? 250 : 30,
                right: this.props.businessPlan ? 300 : 30,
                color: "#585858",
                fontFamily: "Inter",
                fontSize: "12pt",
                overflow: "hidden",
                zIndex: 999999,
              }}
            >
              {this.props.slideConfig.FooterText}
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  isGrouped = (obj) => {
    return obj.props.groupKey !== null;
  };

  ungroupObjects = (groupKey, deleteGroup = true) => {
    let slideObjects = this.state.slideObjects.filter(
      (c) => c.props.groupKey && c.props.groupKey === groupKey && c.type !== SlideTypes.GroupObject,
    );
    let groupObject = this.state.slideObjects.filter(
      (c) => c.props.groupKey === groupKey && c.type === SlideTypes.GroupObject,
    )[0];

    slideObjects.forEach((obj) => {
      obj.updateProps(
        {
          groupKey: null,
          top: groupObject.props.top + obj.props.top,
          left: groupObject.props.left + obj.props.left,
        },
        "props",
        false,
      );
    });

    deleteGroup && this.deleteObj(groupObject);
  };
  getGroupedObjects = (groupKey) => {
    let slideObjects = this.state.slideObjects.filter(
      (c) => c.props.groupKey && c.props.groupKey === groupKey && c.type !== SlideTypes.GroupObject,
    );
    let groupObject = this.state.slideObjects.filter(
      (c) => c.props.groupKey === groupKey && c.type === SlideTypes.GroupObject,
    )[0];

    return slideObjects.map((obj) => {
      return (
        <ContentPanel2
          slideObject={obj}
          groupedObject
          onSelect={this.onSelect}
          parentObject={groupObject}
          recalculateGroupObject={this.recalculateGroupObject}
          getDateRange={this.props.getDateRange}
          disableEdit={this.props.disableEdit}
          scale={this.props.scale}
          cutObjects={this.cutObjects}
          isGrouped={this.isGrouped(obj)}
          onPreview={this.props.onPreview}
          resetPositioning={() => this.resetPositioning()}
          left={this.state.left}
          handleActiveFormatPainter={this.handleActiveFormatPainter}
          activeFormatPainter={this.state.activeFormatPainter}
          formatPainterStyle={this.state.formatPainterStyle}
          top={this.state.top}
          key={obj.key}
          centerOnPage={this.centerOnPage}
          zIndex={obj.props ? obj.props.zIndeX : this.state.maxZindex}
          sentToFront={this.sentToFront}
          sentToBack={this.sentToBack}
          bringForword={this.bringForword}
          bringBackward={this.bringBackward}
          boundries={this.boundries}
          updateSlideProps={this.updateSlideProps}
          handleCopy={this.handleCopy}
          copyObjects={this.copyObjects}
          handleDuplicateObject={() => this.handleDuplicateObject(obj)}
          selected={this.state.selectedObject ? this.state.selectedObject == obj : false}
          multipleSelected={this.isSelected(obj)}
          slideComponentPaste={this.handlePaste}
          moreThanOneSelected={this.state.selectedObjects.length > 1}
          onDelete={(shouldPushChange = true) =>
            this.handleRemoveSlideObject(obj, shouldPushChange)
          }
          onClick={(e) => this.onObjectClick(e, obj)}
          onPositionChanged={() => this.recalculateSlideObjectBoundaries(obj)}
          handleContextMenu={(e) => this.handleContextMenu(e)}
          handleMultipleDelete={() => this.handleMultipleDelete()}
          updatePosition={this.updatePosition}
          distributeH={() => this.distributeHorizontally()}
          distributeV={() => this.distributeVertically()}
          handleReplaceImage={(file) => this.handleReplaceImage(file, index)}
          onNewObject={this.handleOnNewObject}
          onAddTable={this.handleAddTable}
          onUploadImage={this.handleUploadImage}
          selectedObject={this.state.selectedObject}
          onSave={this.handleOnSave}
          move={() => {
            this.didMoveHappen = true;
          }}
          handleDrag={this.handleDrag}
          handleDragEnd={() => this.handleDragEnd()}
          onDragStart={() => this.onDragStart()}
          groupObjects={() => this.groupObjects()}
          ungroupObjects={this.ungroupObjects}
          publishMode={this.props.publishMode || this.props.disableEdit || this.props.preview}
        />
      );
    });
  };

  returnPageSetup = (pageName = true, pageCounter = true) => {
    return (
      <>
        {(!this.props.dontShowHeader && pageName && this.props.businessPlan) ||
        this.props.showPageName ? (
          <div
            style={{
              display: "block",
              marginBottom: "15px",
              fontFamily: "Inter",
              fontSize: "36pt",
              fontWeight: "bold",
              color: "#006FC9",
              zIndex: this.state.maxZindex + 10,
            }}
          >
            {this.props.pageName ? this.props.pageName : this.props.selectedSection.PageName}
          </div>
        ) : (
          ""
        )}

        {!this.props.dontShowHeader && this.returnHeader()}

        {!this.props.dontShowFooter && this.returnFooter(pageCounter)}
      </>
    );
  };

  returnBusinessPlanPreview = () => {
    let chartObjects = this.state.slideObjects.filter((c) => c.type === SlideTypes.chartObject);
    {
      chartObjects.map((obj, index) => {
        return (
          <ContentPanelBusinessPlan
            id={`slide_object_${index}`}
            slideObject={obj}
            onSelect={this.onSelect}
            getDateRange={this.props.getDateRange}
            disableEdit={this.props.disableEdit}
            scale={this.props.scale}
            cutObjects={this.cutObjects}
            onPreview={this.props.onPreview}
            resetPositioning={() => this.resetPositioning()}
            left={this.state.left}
            handleActiveFormatPainter={this.handleActiveFormatPainter}
            businessPlan={true}
            activeFormatPainter={this.state.activeFormatPainter}
            formatPainterStyle={this.state.formatPainterStyle}
            top={this.state.top}
            key={obj.key}
            zIndex={obj.props ? obj.props.zIndeX : this.state.maxZindex}
            sentToFront={this.sentToFront}
            sentToBack={this.sentToBack}
            bringForword={this.bringForword}
            bringBackward={this.bringBackward}
            boundries={this.boundries}
            updateSlideProps={this.updateSlideProps}
            handleCopy={this.handleCopy}
            copyObjects={this.copyObjects}
            handleDuplicateObject={() => this.handleDuplicateObject(obj)}
            selected={this.state.selectedObject ? this.state.selectedObject == obj : false}
            multipleSelected={this.isSelected(obj)}
            slideComponentPaste={this.handlePaste}
            moreThanOneSelected={this.state.selectedObjects.length > 1}
            onDelete={(shouldPushChange = true) =>
              this.handleRemoveSlideObject(obj, shouldPushChange)
            }
            onClick={(e) => this.onObjectClick(e, obj)}
            onPositionChanged={() => this.recalculateSlideObjectBoundaries(obj)}
            handleContextMenu={(e) => this.handleContextMenu(e)}
            handleMultipleDelete={() => this.handleMultipleDelete()}
            updatePosition={this.updatePosition}
            distributeH={() => this.distributeHorizontally()}
            distributeV={() => this.distributeVertically()}
            handleReplaceImage={(file) => this.handleReplaceImage(file, index)}
            onNewObject={this.handleOnNewObject}
            onAddTable={this.handleAddTable}
            onUploadImage={this.handleUploadImage}
            selectedObject={this.state.selectedObject}
            onSave={this.handleOnSave}
            move={() => {
              this.didMoveHappen = true;
            }}
            handleDrag={this.handleDrag}
            handleDragEnd={() => this.handleDragEnd()}
            onDragStart={() => this.onDragStart()}
            groupObjects={() => this.groupObjects()}
            ungroupObjects={this.ungroupObjects}
            publishMode={this.props.publishMode || this.props.disableEdit || this.props.preview}
          />
        );
      });
    }
  };

  onSwapFinished = (list, index1, index2) => {
    const swapCommand = new Swap({
      actionForward: this.runSwap.bind(this),
      actionBackward: this.runSwap.bind(this),
      list,
      draggingIndex: index1,
      droppingIndex: index2,
    });

    this.context.commandHistoryManager.executeCommand(swapCommand);
    this.context.addToGlobalSlideHistory();
  };

  runSwap(newList, index1, index2) {
    array_move(newList, index1, index2);
    this.setState({ slideObjects: newList });
  }

  returnBusinessPlan = (movableObjects, slideObjects) => {
    return (
      <>
        {" "}
        <CustomSortable
          getZoom={this.props.getZoom}
          disabled={this.props.publishMode || this.props.preview || this.props.disableEdit}
          onSwapFinished={(index1, index2) =>
            this.onSwapFinished([...movableObjects, ...slideObjects], index1, index2)
          }
          objectsForContentPanel={slideObjects.map(
            (obj, index) => {
              return {
                slideObject: obj,
                getDateRange: this.props.getDateRange,
                disableEdit: this.props.disableEdit,
                onSelect: this.onSelect,
                scale: this.props.scale,
                cutObjects: this.cutObjects,
                onPreview: this.props.onPreview,
                resetPositioning: () => this.resetPositioning(),
                left: this.state.left,
                handleActiveFormatPainter: this.handleActiveFormatPainter,
                activeFormatPainter: this.state.activeFormatPainter,
                formatPainterStyle: this.state.formatPainterStyle,
                top: this.state.top,
                key: obj.key,
                zIndex: obj.props ? obj.props.zIndeX : this.state.maxZindex,
                sentToFront: this.sentToFront,
                sentToBack: this.sentToBack,
                bringForword: this.bringForword,
                bringBackward: this.bringBackward,
                boundries: this.boundries,
                updateSlideProps: this.updateSlideProps,
                handleCopy: this.handleCopy,
                copyObjects: this.copyObjects,
                handleDuplicateObject: () => this.handleDuplicateObject(obj),
                selected: this.state.selectedObject ? this.state.selectedObject == obj : false,
                multipleSelected: this.isSelected(obj),
                slideComponentPaste: this.handlePaste,
                moreThanOneSelected: this.state.selectedObjects.length > 1,
                onDelete: (e) => this.handleRemoveSlideObject(obj),
                onClick: (e) => this.onObjectClick(e, obj),
                onPositionChanged: () => this.recalculateSlideObjectBoundaries(obj),
                handleContextMenu: (e) => this.handleContextMenu(e),
                handleMultipleDelete: () => this.handleMultipleDelete(),
                updatePosition: this.updatePosition,
                distributeH: () => this.distributeHorizontally(),
                distributeV: () => this.distributeVertically(),
                handleReplaceImage: (file) => this.handleReplaceImage(file, index),
                onNewObject: this.handleOnNewObject,
                onAddTable: this.handleAddTable,
                onUploadImage: this.handleUploadImage,
                selectedObject: this.state.selectedObject,
                preview: this.props.preview,
                addChartImage: this.props.addChartImage,
                onSave: this.handleOnSave,
                move: () => {
                  this.didMoveHappen = true;
                },
                handleDrag: this.handleDrag,
                handleDragEnd: () => this.handleDragEnd(),
                onDragStart: () => this.onDragStart(),
                groupObjects: () => this.groupObjects(),
                ungroupObjects: this.ungroupObjects,
                publishMode: this.props.publishMode || this.props.disableEdit || this.props.preview,
              };
            },
            // onCopy={this.onCopy()}
          )}
        />
        {movableObjects.map((obj, index) => (
          <ContentPanel2
            slideObject={obj}
            onSelect={this.onSelect}
            getDateRange={this.props.getDateRange}
            disableEdit={this.props.disableEdit}
            scale={this.props.scale}
            cutObjects={this.cutObjects}
            onPreview={this.props.onPreview}
            resetPositioning={() => this.resetPositioning()}
            left={this.state.left}
            handleActiveFormatPainter={this.handleActiveFormatPainter}
            businessPlan={true}
            activeFormatPainter={this.state.activeFormatPainter}
            formatPainterStyle={this.state.formatPainterStyle}
            top={this.state.top}
            isBpPrinting={this.props.isBpPrinting}
            isFirstPage={this.props.isFirstPage}
            key={obj.key}
            zIndex={obj.props ? obj.props.zIndeX : this.state.maxZindex}
            sentToFront={this.sentToFront}
            sentToBack={this.sentToBack}
            bringForword={this.bringForword}
            bringBackward={this.bringBackward}
            boundries={this.boundries}
            updateSlideProps={this.updateSlideProps}
            handleCopy={this.handleCopy}
            copyObjects={this.copyObjects}
            handleDuplicateObject={() => this.handleDuplicateObject(obj)}
            selected={this.state.selectedObject ? this.state.selectedObject == obj : false}
            multipleSelected={this.isSelected(obj)}
            slideComponentPaste={this.handlePaste}
            moreThanOneSelected={this.state.selectedObjects.length > 1}
            onDelete={(shouldPushChange = true) =>
              this.handleRemoveSlideObject(obj, shouldPushChange)
            }
            onClick={(e) => this.onObjectClick(e, obj)}
            onPositionChanged={() => this.recalculateSlideObjectBoundaries(obj)}
            handleContextMenu={(e) => this.handleContextMenu(e)}
            handleMultipleDelete={() => this.handleMultipleDelete()}
            updatePosition={this.updatePosition}
            distributeH={() => this.distributeHorizontally()}
            distributeV={() => this.distributeVertically()}
            handleReplaceImage={(file) => this.handleReplaceImage(file, index)}
            onNewObject={this.handleOnNewObject}
            onAddTable={this.handleAddTable}
            onUploadImage={this.handleUploadImage}
            preview={this.props.preview}
            selectedObject={this.state.selectedObject}
            onSave={this.handleOnSave}
            move={() => {
              this.didMoveHappen = true;
            }}
            handleDrag={this.handleDrag}
            handleDragEnd={() => this.handleDragEnd()}
            onDragStart={() => this.onDragStart()}
            groupObjects={() => this.groupObjects()}
            ungroupObjects={this.ungroupObjects}
            publishMode={this.props.publishMode || this.props.disableEdit || this.props.preview}
            // onCopy={this.onCopy()}
          ></ContentPanel2>
        ))}
      </>
    );
  };

  onSelect = (obj) => {
    this.setState({ selectedObject: obj, selectedObjects: [] });
  };

  render() {
    if (
      this.state.selectedObject === "panel" ||
      (!this.state.selectedObject && this.state.selectedObjects.length === 0)
    ) {
      global.pasteListener = this.handlePaste;
    }

    let ClassName = this.slideObjectStrategy.getSlideComponentClassName();
    let movableObjects = this.slideObjectStrategy.getMovableObjects(this.state.slideObjects);
    let slideObjects = this.slideObjectStrategy.getSlideObjects(this.state.slideObjects);
    let panelClassName = "se_panel_placeholder";
    let maskClassName = "";

    if (this.insertMode) {
      maskClassName = "visible";
    }

    if (this.props.jsonFunc) {
      this.props.jsonFunc.returnJsonString = this.returnJsonString;
    }
    if (this.props.addNewObjectFuncs) {
      this.props.addNewObjectFuncs.handleOnNewObject = this.handleOnNewObject;
    }

    const slideObjectsToBeRendered = this.state.slideObjects.filter(
      (c) => (c.props && !c.props.groupKey) || c.type === SlideTypes.GroupObject,
    );

    if (this.props.printMode) {
      return (
        <>
          {this.props.businessPlan ? (
            <div
              className={"print_slide_comp"}
              style={{ backgroundColor: global.Modeliks.BusinessPlanConfig.BackgroundColor }}
            >
              {this.returnPageSetup()}
              {this.returnBusinessPlan(movableObjects, slideObjects)}
            </div>
          ) : (
            <div
              className={"print_slide_comp"}
              style={{ backgroundColor: this.props.slideConfig.BackgroundColor }}
            >
              {this.props.children && this.props.children}
              {slideObjectsToBeRendered
                .filter((obj) => obj.props.left < 1280 && obj.props.top < 720)
                .map((obj, index) => (
                  <ContentPanel2
                    slideObject={obj}
                    groupedObjects={obj.type === SlideTypes.GroupObject ? this.getGroupedObjects(obj.props.groupKey) : null}
                    isGrouped={this.isGrouped(obj)}
                    onSelect={this.onSelect}
                    getDateRange={this.props.getDateRange}
                    disableEdit={true}
                    scale={this.props.scale}
                    cutObjects={this.cutObjects}
                    onPreview={this.props.onPreview}
                    resetPositioning={() => this.resetPositioning()}
                    left={this.state.left}
                    handleActiveFormatPainter={this.handleActiveFormatPainter}
                    activeFormatPainter={this.state.activeFormatPainter}
                    formatPainterStyle={this.state.formatPainterStyle}
                    top={this.state.top}
                    key={obj.key}
                    zIndex={obj.props ? obj.props.zIndeX : this.state.maxZindex}
                    copyObjects={this.copyObjects}
                    sentToFront={this.sentToFront}
                    sentToBack={this.sentToBack}
                    bringForword={this.bringForword}
                    bringBackward={this.bringBackward}
                    boundries={this.boundries}
                    updateSlideProps={this.updateSlideProps}
                    handleCopy={this.handleCopy}
                    handleDuplicateObject={() => this.handleDuplicateObject(obj)}
                    selected={this.state.selectedObject ? this.state.selectedObject == obj : false}
                    multipleSelected={this.isSelected(obj)}
                    slideComponentPaste={this.handlePaste}
                    moreThanOneSelected={this.state.selectedObjects.length > 1}
                    onDelete={(shouldPushChange = true) =>
                      this.handleRemoveSlideObject(obj, shouldPushChange)
                    }
                    onClick={(e) => this.onObjectClick(e, obj)}
                    onPositionChanged={() => this.recalculateSlideObjectBoundaries(obj)}
                    handleContextMenu={(e) => this.handleContextMenu(e)}
                    handleMultipleDelete={() => this.handleMultipleDelete()}
                    updatePosition={this.updatePosition}
                    distributeH={() => this.distributeHorizontally()}
                    distributeV={() => this.distributeVertically()}
                    handleReplaceImage={(file) => this.handleReplaceImage(file, index)}
                    onNewObject={this.handleOnNewObject}
                    onAddTable={this.handleAddTable}
                    onUploadImage={this.handleUploadImage}
                    selectedObject={this.state.selectedObject}
                    onSave={this.handleOnSave}
                    move={() => {
                      this.didMoveHappen = true;
                    }}
                    handleDrag={this.handleDrag}
                    handleDragEnd={() => this.handleDragEnd()}
                    onDragStart={() => this.onDragStart()}
                    groupObjects={() => this.groupObjects()}
                    ungroupObjects={this.ungroupObjects}
                    publishMode={true}
                    // onCopy={this.onCopy()}
                  ></ContentPanel2>
                ))}
              {this.returnPageSetup()}
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {this.state.menuProps && (
            <SCContextMenuPortal>
              <ContextMenu
                handleClose={() => this.handleClose()}
                anchorPoint={this.state.anchorPoint}
                menuProps={this.state.menuProps}
              >
                <MenuItem className="cm_icon_helper">
                  <Cut className="cm_icon" />
                  <span className="cm_btn_name" onClick={this.cutObjects}>
                    Cut
                  </span>
                  <span className="cm_helper_text">Ctrl+X</span>
                </MenuItem>
                {/*<MenuItem className='cm_icon_helper'><Copy className='cm_icon'/><span*/}
                {/*className='cm_btn_name'>Copy</span><span className='cm_helper_text'>Ctrl+C</span></MenuItem>*/}
                <MenuItem className="cm_icon_helper">
                  <Paste className="cm_icon" />
                  <span className="cm_btn_name" onClick={this.handlePaste}>
                    Paste
                  </span>
                  <span className="cm_helper_text">Ctrl+V</span>
                </MenuItem>
                <MenuItem>
                  <span className="cm_btn_name">Delete</span>
                </MenuItem>
                <div className="cm_divider" />

                {/*<MenuItem><span className='cm_btn_name'>Change Background</span></MenuItem>*/}
              </ContextMenu>
            </SCContextMenuPortal>
          )}
          {!this.props.publishMode && !this.props.disableHeader && (
            <HeaderDefault
              documentTitle={this.props.documentTitle}
              ScenarioName={this.props.PitchScenarioInfo && this.props.PitchScenarioInfo.Name}
              ScenarioNamePrefix={this.props.ScenarioNamePrefix}
              type={this.props.type}
              saveContent={this.handleOnSave}
              setSaving={this.props.setSaving}
              objectLoad={this.props.objectLoad}
              businessPlan={this.props.businessPlan}
              insertMode={this.insertMode}
              setInsertMode={this.setInsertMode}
              setPublish={this.props.setPublish}
              openFreeTrialDialog={this.props.openFreeTrialDialog}
              publish={this.props.publish}
              renderedPublishSlides={this.props.renderedPublishSLides}
              exportToPdf={this.props.exportToPdf}
              preview={this.props.preview}
              canShare={this.props.canShare}
              canUndo={this.state.canUndo}
              canRedo={this.state.canRedo}
              ScenarioID={this.PitchScenarioID}
              PitchScenarioInfo={this.props.PitchScenarioInfo}
              onNewObject={this.handleOnNewObject}
              onAddTable={this.handleAddTable}
              undo={this.handleUndo.bind(this)}
              redo={this.handleRedo.bind(this)}
              onUploadImage={this.handleUploadImage}
              selectedObject={this.state.selectedObject}
              disableEdit={this.props.disableEdit}
              activeFormatPainter={this.state.activeFormatPainter}
              onSave={this.handleOnSave}
              update={this.handleThemeBackgroundUpdate}
              setCounter={this.props.setCounter}
              setFooter={() => this.forceUpdate()}
              setLogo={() => this.forceUpdate()}
              selectedTemplateID={this.props.selectedTemplateID}
              selectedTemplateColor={this.props.selectedTemplateColor}
              changeTemplateColor={this.updateTemplateColor}
              afterPrint={this.props.afterPrint}
              newIcon={true}
              changeDeckColor={this.props.changeDeckColor}
              beforeSlidePrint={this.props.beforeSlidePrint}
              handleNewScribble={() => this.handleNewScribble()}
              onPreview={() => {
                this.props.onPreview();
                // setTimeout(this.props.scale.handleResize(), 150);
              }}
              admin={this.props.isAdmin}
            />
          )}
          <MenuObject
            onNewObject={this.handleOnNewObject}
            onNewTableObj={this.handleOnNewObject}
            onApplyTemplate={this.handleOnApplyTemplate}
            onSave={this.handleOnSave}
          />
          {/*<RightMenuObject selectedObj={this.state.selectedObject}/>*/}
          {this.props.businessPlan && (this.props.preview || this.props.publishMode) ? (
            <div
              className={this.props.pageBreakAfter ? "page_container" : ""}
              style={{ position: "relative" }}
            >
              {this.props.showPageName && (
                <div
                  style={{
                    marginTop: "30px",
                    fontFamily: "Inter",
                    fontSize: "36pt",
                    fontWeight: "bold",
                    color: "#006FC9",
                  }}
                >
                  {this.props.pageName ? this.props.pageName : ""}
                </div>
              )}

              {this.returnBusinessPlan(movableObjects, slideObjects)}
            </div>
          ) : (
            <ScalePanel
              businessPlan={this.props.businessPlan}
              uniqueId={this.props.uniqueId}
              className={ClassName}
              maskClassName={maskClassName}
              scale={this.props.scale}
              width={this.props.businessPlan ? 816 : 1280}
              height={this.props.businessPlan ? 1154 : 720}
              getZoom={this.props.getZoom}
            >
              {this.props.children && this.props.children}

              {!this.props.formatOptions &&
                !this.props.publishMode &&
                !this.props.hideTemplateButtons && (
                  <TemplateButtonsPortal>
                    <SectionName
                      addNewSection={this.handleAddNewSection}
                      disableEdit={this.props.disableEdit}
                      openDialog={this.props.openDialog ? this.props.openDialog : false}
                      saveSection={() => this.handleOnSave()}
                    />
                  </TemplateButtonsPortal>
                )}

              <div
                //*this.state.selectedObject == 'panel' ? 'se_panel_placeholder se_panel_placeholder_selected' :*/
                className={panelClassName}
                style={this.state.style}
                onMouseDown={(e) => {
                  if (!this.props.disableEdit) {
                    this.onPanelMouseDown(e);
                  }
                }}
                onContextMenu={(event) => {
                  if (!this.props.disableEdit) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.handleContextMenu(event);
                  }
                }}
              >
                <Selector
                  ref={this.selectorRef}
                  onMultipleDelete={this.handleMultipleDelete}
                  visible={this.state.selectedObjects.length > 0}
                />
              </div>
              {this.props.businessPlan || (this.props.businessPlan && this.props.publishMode) ? (
                <>
                  {this.returnPageSetup()}
                  {this.returnBusinessPlan(movableObjects, slideObjects)}
                </>
              ) : (
                <>
                  {this.returnPageSetup()}
                  {slideObjectsToBeRendered.map((obj, index) => (
                    <ContentPanel2
                      slideObject={obj}
                      onSelect={this.onSelect}
                      groupedObjects={
                        obj.type === SlideTypes.GroupObject
                          ? this.getGroupedObjects(obj.props.groupKey)
                          : null
                      }
                      isGrouped={this.isGrouped(obj)}
                      recalculateObjectsAgainstRatio={this.recalculateObjectsAgainstRatio}
                      recalculateGroupObject={this.recalculateGroupObject}
                      getDateRange={this.props.getDateRange}
                      canGroupObjects={this.canGroupObjects()}
                      disableEdit={this.props.disableEdit}
                      scale={this.props.scale}
                      cutObjects={this.cutObjects}
                      onPreview={this.props.onPreview}
                      resetPositioning={() => this.resetPositioning()}
                      left={this.state.left}
                      handleActiveFormatPainter={this.handleActiveFormatPainter}
                      activeFormatPainter={this.state.activeFormatPainter}
                      formatPainterStyle={this.state.formatPainterStyle}
                      top={this.state.top}
                      key={obj.key}
                      centerOnPage={this.centerOnPage}
                      zIndex={obj.props ? obj.props.zIndeX : this.state.maxZindex}
                      sentToFront={this.sentToFront}
                      sentToBack={this.sentToBack}
                      bringForword={this.bringForword}
                      bringBackward={this.bringBackward}
                      boundries={this.boundries}
                      updateSlideProps={this.updateSlideProps}
                      handleCopy={this.handleCopy}
                      copyObjects={this.copyObjects}
                      handleDuplicateObject={() => this.handleDuplicateObject(obj)}
                      selected={
                        this.state.selectedObject ? this.state.selectedObject == obj : false
                      }
                      multipleSelected={this.isSelected(obj)}
                      slideComponentPaste={this.handlePaste}
                      moreThanOneSelected={this.state.selectedObjects.length > 1}
                      onDelete={(shouldPushChange = true) =>
                        this.handleRemoveSlideObject(obj, shouldPushChange)
                      }
                      onClick={(e) => this.onObjectClick(e, obj)}
                      onPositionChanged={() => this.recalculateSlideObjectBoundaries(obj)}
                      handleContextMenu={(e) => this.handleContextMenu(e)}
                      handleMultipleDelete={() => this.handleMultipleDelete()}
                      updatePosition={this.updatePosition}
                      distributeH={() => this.distributeHorizontally()}
                      distributeV={() => this.distributeVertically()}
                      handleReplaceImage={(file) => this.handleReplaceImage(file, index)}
                      onNewObject={this.handleOnNewObject}
                      onAddTable={this.handleAddTable}
                      onUploadImage={this.handleUploadImage}
                      selectedObject={this.state.selectedObject}
                      onSave={this.handleOnSave}
                      move={() => {
                        this.didMoveHappen = true;
                      }}
                      handleDrag={this.handleDrag}
                      handleDragEnd={() => this.handleDragEnd()}
                      onDragStart={() => this.onDragStart()}
                      groupObjects={() => this.groupObjects()}
                      ungroupObjects={this.ungroupObjects}
                      publishMode={
                        this.props.publishMode || this.props.disableEdit || this.props.preview
                      }
                      // onCopy={this.onCopy()}
                    ></ContentPanel2>
                  ))}
                </>
              )}
            </ScalePanel>
          )}

          {!this.props.preview && <div id="se_sc_context_menu"></div>}
        </>
      );
    }
  }
}

<script src="https://cdn.rawgit.com/naptha/tesseract.js/1.0.10/dist/tesseract.js"></script>;

SlideComponent.contextType = CommandManagerContext;

export default SlideComponent;
