import Revenue from "../index";
import CalculatedDriver from "../../CalculatedDriver";
import { RevenueTypes } from "../../../../components/constants/finance";
import { DriverCategories, UnitTypes, SpecialChar } from "../../CalculatedDriver/constants";

class UnitSales extends Revenue {
  UnitSales;
  UnitPrices;

  RevenueType = RevenueTypes.UnitSales;

  constructor(db_record) {
    super(db_record);
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$RevenueName",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    UnitSales: {
      driverName: `Unit Sales${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "UnitSales",
      driverID: "unit_sales",
    },
    UnitPrices: {
      driverName: `Unit Prices${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "UnitPrices",
      driverID: "unit_prices",
    },
  });

  cloneStream = (callBack, convert_Revenue = true) => {
    const newRevenue = UnitSales.createNew(this);
    let OldDrivers = this.getAllDrivers();
    let newDrivers = newRevenue.getAllDrivers();
    OldDrivers.forEach((driver, index) => {
      let olddriver = driver;
      let newdriver = newDrivers[index];

      if (olddriver.Formula) {
        newdriver.Formula = olddriver.Formula;
        newdriver.replaceAll(this.Ref_ID, newRevenue.Ref_ID);
      }

      for (let i = 0; i < olddriver.Values.length; i++) {
        if (olddriver) {
          // console.log('Old', olddriver.Values[i], newdriver.Values[i])
        }
      }
    });
  };

  static convert_Revenue = (revenue) => {
    const newUnitSales = new UnitSales();
    newUnitSales.ID = revenue.ID;
    newUnitSales.IsCreated = true;
    newUnitSales.RevenueName = revenue.RevenueName;
    newUnitSales.RevenueType = RevenueTypes.UnitSales;
    newUnitSales.Totals = CalculatedDriver.createDriverFromTable(
      newUnitSales,
      Revenue.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newUnitSales.UnitSales = CalculatedDriver.createDriverFromTable(
      newUnitSales,
      UnitSales.DriversDesc.UnitSales.driverID,
      UnitTypes.Units,
      DriverCategories.Sum,
    );
    newUnitSales.UnitPrices = CalculatedDriver.createDriverFromTable(
      newUnitSales,
      UnitSales.DriversDesc.UnitPrices.driverID,
      UnitTypes.Price,
      DriverCategories.Average,
    );
    newUnitSales.Totals.setFormula(
      `|${newUnitSales.UnitSales.ID}|*|${newUnitSales.UnitPrices.ID}|`,
    );
    global.Modeliks.DateHelper.years_all.forEach((y) => {
      if (!y.Active) {
        const curYearTotal = newUnitSales.Totals.getItemByDateSufix(y.sufix);
        const curYearSales = newUnitSales.UnitSales.getItemByDateSufix(y.sufix);
        const curYearPrices = newUnitSales.UnitPrices.getItemByDateSufix(y.sufix);
        curYearPrices.Formula = `${curYearTotal}/${curYearSales}`;
      }
    });

    newUnitSales.changeDriversName();

    return newUnitSales;
  };
}

export default UnitSales;
