import Revenue from "../index";
import CalculatedDriver from "../../CalculatedDriver";
import { RevenueTypes } from "../../../../components/constants/finance";
import { DriverCategories, UnitTypes, SpecialChar } from "../../CalculatedDriver/constants";
import { PeriodTypes } from "../../../dates";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import MxIDHelper from "../../../MxIDHelper";
import { WorkingCapitalTypes } from "../../WorkingCapital/constants";

const quantity_props = {
  driver_states: {
    with_quantity_split: "with_quantity_split",
    single_children_values: "single_children_values",
  },
};

class Subscription extends Revenue {
  Quantity;
  Quantity_Split;
  SinglePrice;
  ChurnRate;
  Refund;
  children = [];
  RevenueName = "";

  CustomersAtStartInit;
  SubscriptionPeriod;

  SinglePrice_Value = null;
  ChurnRate_Value = null;

  subscriptionplans;

  get IsPortfolio() {
    return this.children && this.children.length > 0;
  }

  RevenueType = RevenueTypes.Subscriptions;

  constructor(db_record) {
    super(db_record);

    if (this.isNew == false) {
      if (this.db_record) {
        this.clean();
      }

      if (!this.IsPortfolio) {
        this.setDriversFromDataStorageSub();
      }
    }

    this.SaveRevenue = this.Save;
    this.Save = this.SaveSubscription;
  }

  static Fields = Object.assign(
    {
      Quantity: "quantity",
      SinglePrice: "single_price",
      ChurnRate: "churn_rate",
      Refund: "refund",
    },
    Revenue.Fields,
  );

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$RevenueName",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    Quantity: {
      driverName: `Signups${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "Quantity",
      driverID: "quantity",
      unit: UnitTypes.Integer,
      category: DriverCategories.Sum,
    },
    Quantity_Split: {
      driverName: `Signups Split${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "Quantity_Split",
      driverID: "quantity_split",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
      optional: true,
    },
    SinglePrice: {
      driverName: `Subscription Price${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "SinglePrice",
      driverID: "single_price",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    ChurnRate: {
      driverName: `Churn Rate${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "ChurnRate",
      driverID: "churn_rate",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    Refund: {
      driverName: `Refund %${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "Refund",
      driverID: "refund",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    CustomersAtStart: {
      driverName: `Customers At Start${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "CustomersAtStart",
      driverID: "customers_at_start",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
    },
    CustomersAtEndOfPeriod: {
      driverName: `Customers At End Of Period${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "CustomersAtEndOfPeriod",
      driverID: "customers_at_end_of_period",
      unit: UnitTypes.Units,
      category: DriverCategories.LastPeriod,
    },
    CustomersDueForRenewal: {
      driverName: `Customers Due For Renewal${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "CustomersDueForRenewal",
      driverID: "customers_due_for_renewal",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
    },
    RenewingCustomersPostChurn: {
      driverName: `Renewing Customers Post Churn${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "RenewingCustomersPostChurn",
      driverID: "renewing_customers_post_churn",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
    },
    ChurnedCustomers: {
      driverName: `Churned Customers${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "ChurnedCustomers",
      driverID: "churned_customers",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
    },
    RefundsNum: {
      driverName: `Refunds #${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "RefundsNum",
      driverID: "refunds_num",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
    },
    TotalCustomersDueForPayment: {
      driverName: `Customers Due For Payment${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "TotalCustomersDueForPayment",
      driverID: "total_customers_due_for_payment",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
    },
    CashCollection: {
      driverName: `Cash Collection${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "CashCollection",
      driverID: "cash_collection",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    SubscriptionRevenue: {
      driverName: `Subscription Revenue${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "SubscriptionRevenue",
      driverID: "subscription_revenue",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  IsCreated = false;
  clean = (cleanDrivers = false, removedDrivers) => {
    if (this.db_record && this.Quantity) {
      this.RevenueName = this.db_record.RevenueName;
      this.RevenueType = this.db_record.RevenueType;
      this.ID_ParentRevenue = this.db_record.ID_ParentRevenue;
      // this.cleanDrivers()
      if (this.db_record.children && this.db_record.children.length > 0) {
        this.setChildrenData();
      }
      if (cleanDrivers) {
        if (this.IsPortfolio) {
          this.cleanDrivers();
        }

        if (this.children && this.children.length > 0) {
          // this.setChildrenData(removedDrivers)
          this.children.forEach((c) => {
            global.Modeliks.DriverValuesStore.getItem(c.getSubscriptionPeriodID()).cleanValue();
            global.Modeliks.DriverValuesStore.getItem(c.getCustomersAtStartInitID()).cleanValue();
            c.getAllDrivers().forEach((d) => d.cleanDriver());
          });
        }
      }
    }
  };

  setDriversFromDataStorageSub = () => {
    this.SubscriptionPeriod = global.Modeliks.DriverValuesStore.getItem(
      this.getSubscriptionPeriodID(),
    );
    this.CustomersAtStartInit = global.Modeliks.DriverValuesStore.getItem(
      this.getCustomersAtStartInitID(),
    );

    this.subscriptionplans = [
      {
        SubscriptionName: this.RevenueName,
        SubscriptionPeriodMonths: this.SubscriptionPeriod && this.SubscriptionPeriod.Value,
        ID: this.ID,
        Delete: false,
      },
    ];
  };

  setChildrenData = (children) => {
    this.children.forEach((c) => {
      if (c.db_record === null) {
        c.getAllDrivers().forEach((d) => global.Modeliks.DriversStore.remove(d));
      }
    });

    this.children = this.db_record.children.filter((c) => c.db_record);

    this.subscriptionplans = this.children.map((c) => {
      c.SubscriptionPeriod.cleanValue();
      return {
        SubscriptionName: c.RevenueName,
        SubscriptionPeriodMonths: c.SubscriptionPeriod.Value,
        ID: c.ID,
        Delete: false,
      };
    });
  };

  #DeleteValues = (callBack) => {
    global.Modeliks.del(
      global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
      {
        ID: this.getSubscriptionPeriodID(),
        ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
      },
      (res) => {
        global.Modeliks.del(
          global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
          {
            ID: this.getCustomersAtStartInitID(),
            ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
          },
          (res) => {
            if (callBack) {
              callBack();
            }
          },
        );
      },
    );
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      this.#DeleteValues(() => {
        if (this.children.length > 0) {
          const accountReceivable = global.Modeliks.WorkingCapitalStore.find(
            (d) => d.Type === WorkingCapitalTypes.AccountReceivable,
          );
          accountReceivable.cleanWorkingCapital(this.ID, Revenue.TableName, () => {
            this.children.forEach((children, index) => {
              children.DeleteFunc(children.ID, () => {
                children.#DeleteValues(() => {
                  if (this.children.length === index + 1) {
                    if (callBack) {
                      callBack();
                    }
                  }
                });
              });
            });
          });
        } else {
          if (callBack) {
            callBack();
          }
        }
      });
    });
  };

  cloneRevenue = (id = MxIDHelper.newID(), shouldCloneDrivers = true) => {
    const newRevenue = new Subscription();
    newRevenue.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newRevenue.ID = "new_" + id;
    newRevenue.RevenueName = this.RevenueName;
    newRevenue.RevenueType = this.RevenueType;
    newRevenue.subscriptionplans = this.subscriptionplans;
    if (shouldCloneDrivers) {
      if (this.IsPortfolio) {
        this.cloneDrivers(newRevenue.ID);
        newRevenue.setDriversFromDataStorage();

        newRevenue.children = [];
        this.children.forEach((plan) => {
          const Children = new Subscription();
          Children.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
          Children.ID = MxIDHelper.newID();
          Children.RevenueName = plan.RevenueName;
          Children.RevenueType = plan.RevenueType;
          plan.cloneDrivers(Children.ID);

          Children.SubscriptionPeriod = plan.SubscriptionPeriod.cloneCalculatedDriver_Value(
            Children.ID,
            plan.ID,
          );
          Children.CustomersAtStartInit = plan.CustomersAtStartInit.cloneCalculatedDriver_Value(
            Children.ID,
            plan.ID,
          );
          Children.setDriversFromDataStorage();

          Object.values(this.constructor.DriversDesc).forEach((driver) => {
            newRevenue[driver.fieldName].Values.forEach((driverValue) => {
              if (driverValue.Formula) {
                driverValue.Formula = driverValue.Formula.replaceAll(plan.ID, Children.ID);
                driverValue.Formula = driverValue.Formula.replaceAll(this.ID, newRevenue.ID);
              }
            });
            if (Children[driver.fieldName].Formula) {
              Children[driver.fieldName].Formula = Children[driver.fieldName].Formula.replaceAll(
                this.ID,
                newRevenue.ID,
              );
            }
            Children[driver.fieldName].Values.forEach((driverValue) => {
              if (driverValue.Formula) {
                driverValue.Formula = driverValue.Formula.replaceAll(this.ID, newRevenue.ID);
              }
            });
          });

          newRevenue.children.push(Children);
        });

        return newRevenue;
      } else {
        this.cloneDrivers(newRevenue.ID);
        newRevenue.CustomersAtStartInit = this.CustomersAtStartInit.cloneCalculatedDriver_Value(
          newRevenue.ID,
          this.ID,
        );
        newRevenue.SubscriptionPeriod = this.SubscriptionPeriod.cloneCalculatedDriver_Value(
          newRevenue.ID,
          this.ID,
        );
        newRevenue.setDriversFromDataStorage();
        // newRevenue.buildPeriodsData();
        return newRevenue;
      }
    } else {
      return newRevenue;
    }
  };

  SaveSubscription = (callBack, saveDrivers = false) => {
    if (this.IsPortfolio) {
      this.SavePortfolioSubscription(callBack);
      return;
    }

    this.SaveRevenue(
      (newID) => {
        this.SubscriptionPeriod.Save(() => {
          this.CustomersAtStartInit.Save(() => {
            if (saveDrivers) {
              this.SaveDrivers(() => {
                callBack(this.ID);
              });
            } else {
              callBack(this.ID);
            }
          });
        });
      },
      false,
      false,
    );
  };

  SavePortfolioSubscription = (callBack) => {
    let saveCounter = 0;
    this.SaveRevenue(
      (newID) => {
        this.children.forEach((plan) => {
          plan.changeDriversName();
          plan.ID_ParentRevenue = this.ID;
          plan.SaveSubscription(
            () => {
              saveCounter = saveCounter + 1;
              if (saveCounter === this.children.length) {
                this.SaveDrivers(() => {
                  callBack();
                });
              }
            },
            false,
            false,
          );
        });
      },
      false,
      false,
    );
  };

  getSubscriptionPeriodID = () => {
    return `${Subscription.TableName}-${this.ID}-subscription_period`;
  };

  getCustomersAtStartInitID = () => {
    return `${Subscription.TableName}-${this.ID}-customers_at_start_init`;
  };

  getFrozenValueFieldID = (field) => {
    return `${this[field].ID}_frozen_value`;
  };

  getMonthDatesBefore = () => {
    return global.Modeliks.DateHelper.months_before.filter(
      (c) => c.Order >= -this.SubscriptionPeriod.Value,
    );
  };
  getAllMonthsDatesBefore = () => {
    return global.Modeliks.DateHelper.months_before;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  periodsData = {};

  buildPeriodsData = () => {
    if (Object.keys(this.periodsData).length === 0) {
      const allPeriods = this.Quantity.Values.map((c) => c.Date);
      allPeriods.forEach((period) => {
        this.periodsData[period.dateID] = {};
        Object.values(this.constructor.DriversDesc).forEach(
          (driver) =>
            (this.periodsData[period.dateID][driver.fieldName] = this[
              driver.fieldName
            ].getItemByDateSufix(period.sufix)),
        );
        this.periodsData[period.dateID].Date = period;
      });
    }
  };

  createExtraPeriods = () => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      this[driver.fieldName].addMonths(48, true);
    });

    this.buildPeriodsData();

    const monthsBefore = this.getMonthDatesBefore();
    const startMonth = this.periodsData[global.Modeliks.DateHelper.months[0].dateID];
    // prev months
    monthsBefore.forEach((month) => {
      this.periodsData[month.dateID].Quantity.Formula =
        `${this.CustomersAtStartInit}/${this.SubscriptionPeriod}`;
      this.periodsData[month.dateID].SinglePrice.Formula = `${startMonth.SinglePrice}`;
      this.periodsData[month.dateID].Refund.Value = 0;
      this.periodsData[month.dateID].ChurnRate.Value = 0;
    });

    // after months
    global.Modeliks.DateHelper.months_after.forEach((month) => {
      const yearData = this.periodsData[month.year.dateID];
      this.periodsData[month.dateID].SinglePrice.Formula = `${yearData.SinglePrice}`;
      this.periodsData[month.dateID].Refund.Formula = `${yearData.Refund} * 100`;
      this.periodsData[month.dateID].ChurnRate.Formula = `${yearData.ChurnRate} * 100`;
    });

    global.Modeliks.DateHelper.years.forEach((year) => {
      const month = year.months[6];
      const yearData = this.periodsData[year.dateID];
      this.periodsData[month.dateID].Quantity.Formula = `${yearData.Quantity}`;
    });
  };

  createYearBeforeFormulas = () => {
    global.Modeliks.DateHelper.years_before.forEach((year) => {
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      this.periodsData[year.dateID].Quantity.Formula =
        `MxMath.Sum([${months.map((c) => c.Quantity.ID_f).join(",")}])`;
      this.periodsData[year.dateID].SinglePrice.Formula =
        `MxMath.Sum([${months.map((c) => c.CashCollection.ID_f).join(",")}]) / MxMath.Sum([${months.map((c) => c.TotalCustomersDueForPayment.ID_f).join(",")}])`;
      this.periodsData[year.dateID].Refund.Formula =
        `MxMath.Sum([${months.map((c) => c.RefundsNum.ID_f).join(",")}]) / MxMath.Sum([${months.map((c) => c.Quantity.ID_f).join(",")}]) * 100`;
      this.periodsData[year.dateID].ChurnRate.Formula =
        `MxMath.Sum([${months.map((c) => c.ChurnedCustomers.ID_f).join(",")}]) / MxMath.Sum([${months.map((c) => (this.IsPortfolio ? c.CustomersAtStart.ID_f : c.CustomersDueForRenewal.ID_f)).join(",")}]) * 100`;
    });
  };

  createMonthsBeforeFormulas = () => {
    if (Object.keys(this.periodsData).length === 0) {
      this.buildPeriodsData();
    }

    const monthsBefore = this.getAllMonthsDatesBefore();
    const firstMonth = this.periodsData[global.Modeliks.DateHelper.months[0].dateID];

    monthsBefore.forEach((month) => {
      if (month.Order * -1 <= this.SubscriptionPeriod.Value) {
        this.periodsData[month.dateID].CustomersDueForRenewal.Formula =
          `${this.CustomersAtStartInit} / ${this.SubscriptionPeriod.Value}`;
        this.periodsData[month.dateID].CashCollection.Formula =
          `${this.periodsData[month.dateID].CustomersDueForRenewal} * ${this.periodsData[month.dateID].SinglePrice}`;
      } else {
        this.periodsData[month.dateID].CustomersDueForRenewal.Formula = null;
        this.periodsData[month.dateID].CashCollection.Formula = null;
      }

      this.periodsData[month.dateID].SinglePrice.Formula = `${firstMonth.SinglePrice}`;
    });
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const monthsBefore = this.getAllMonthsDatesBefore();

    months.forEach((month) => {
      const curMonth = this.periodsData[month.dateID];

      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
      const renewMonthDate = months.find(
        (c) => c.Order == month.Order - this.SubscriptionPeriod.Value,
      );
      const renewMonth = renewMonthDate ? this.periodsData[renewMonthDate.dateID] : null;
      const subscriptionMonths = [...monthsBefore, ...months]
        .filter(
          (c) => c.Order <= month.Order && c.Order > month.Order - this.SubscriptionPeriod.Value,
        )
        .map((c) => this.periodsData[c.dateID]);

      if (prevMonth) {
        curMonth.CustomersAtStart.Formula = `${prevMonth.CustomersAtEndOfPeriod}`;
      } else {
        curMonth.CustomersAtStart.Formula = `${this.CustomersAtStartInit}`;
      }

      curMonth.CustomersAtEndOfPeriod.Formula = `${curMonth.CustomersAtStart} - ${curMonth.ChurnedCustomers} + ${curMonth.Quantity}`;
      if (renewMonth) {
        curMonth.CustomersDueForRenewal.Formula = `Math.round(${renewMonth.Quantity} + ${renewMonth.RenewingCustomersPostChurn})`;
      } else {
        curMonth.CustomersDueForRenewal.Formula = `${this.CustomersAtStartInit} / ${this.SubscriptionPeriod.Value}`;
      }

      curMonth.RenewingCustomersPostChurn.Formula = `${curMonth.CustomersDueForRenewal} - ${curMonth.ChurnedCustomers}`;
      curMonth.ChurnedCustomers.Formula = `Math.round(${curMonth.CustomersDueForRenewal} * ${curMonth.ChurnRate})`;
      curMonth.RefundsNum.Formula = `Math.round(${curMonth.Quantity} * ${curMonth.Refund})`;
      curMonth.TotalCustomersDueForPayment.Formula = `Math.round(${curMonth.RenewingCustomersPostChurn} + ${curMonth.Quantity} - ${curMonth.RefundsNum})`;
      curMonth.CashCollection.Formula = `${curMonth.TotalCustomersDueForPayment} * ${curMonth.SinglePrice}`;
      curMonth.SubscriptionRevenue.Formula = `MxMath.Sum([${subscriptionMonths.map((c) => c.CashCollection.ID_f).join(",")}]) / ${this.SubscriptionPeriod}`;
    });
    this.createMonthsBeforeFormulas();
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      curYear.CustomersAtStart.Formula = `${firstMonth.CustomersAtStart}`;
      curYear.RefundsNum.Formula = `Math.round( ${curYear.Quantity} * ${curYear.Refund} )`;
      curYear.CustomersAtEndOfPeriod.Formula = `${lastMonth.CustomersAtEndOfPeriod}`;
      curYear.CashCollection.Formula = `MxMath.Sum([${months.map((c) => c.CashCollection.ID_f).join(",")}])`;
      curYear.SubscriptionRevenue.Formula = `MxMath.Sum([${months.map((c) => c.SubscriptionRevenue.ID_f).join(",")}])`;
      curYear.CustomersDueForRenewal.Formula = `MxMath.Sum([${months.map((c) => c.CustomersDueForRenewal.ID_f).join(",")}])`;
      // curYear.ChurnedCustomers.Formula = `Math.round( ${curYear.CustomersDueForRenewal} * ${curYear.ChurnRate} )`;
      curYear.ChurnedCustomers.Formula = `MxMath.Sum([${months.map((c) => c.ChurnedCustomers.ID_f).join(",")}])`;
      curYear.TotalCustomersDueForPayment.Formula = `MxMath.Sum([${months.map((c) => c.TotalCustomersDueForPayment.ID_f).join(",")}])`;
      // curYear.RenewingCustomersPostChurn.Formula = `${curMonth.CustomersDueForRenewal} - ${curMonth.ChurnedCustomers}`;
    });
  };

  setTotalsFormula = () => {
    this.Totals.Values.forEach((total) => {
      const subRevenue = this.SubscriptionRevenue.Values.find(
        (c) => c.Date.dateID == total.Date.dateID,
      );
      total.Formula = `${subRevenue} Actual(${subRevenue.ID_f_actual})`;
    });
  };

  convertToSingleValue = (field) => {
    if (this.IsPortfolio) {
      this.children.forEach((plan) => plan.convertToSingleValue(field));
      return;
    }
    this[field].convertToSingleValue();

    if (field === Subscription.DriversDesc.SinglePrice.fieldName) {
      this.createMonthsBeforeFormulas();
    }
  };
  removeSingleValue = (field) => {
    if (this.IsPortfolio) {
      this.children.forEach((plan) => plan.removeSingleValue(field));
      return;
    }

    this[field].removeSingleValue();

    if (field === Subscription.DriversDesc.SinglePrice.fieldName) {
      this.createMonthsBeforeFormulas();
    }
  };

  hasSingleValue = (field) => {
    if (this.IsPortfolio) {
      return this.children[0].hasSingleValue(field);
    }

    return this[field].IsSimple;
  };

  getFrozenFieldName = (field) => {
    return field + "_Value";
  };

  getSingleDriverValue = (field) => {
    this[field].getSingleDriverValue();
  };

  getAllDates = () => {
    return global.Modeliks.DateHelper.all;
  };

  useQuantitySplit = (driver = null) => {
    const AllDates = this.getAllDates();
    if (this.IsPortfolio) {
      this.Quantity.removeFormula();
      // this.Quantity.Formula = null;
      AllDates.forEach((date) => {
        const curDriver = this.Quantity.getItemByDateSufix(date.sufix);
        if (curDriver.StateExists(quantity_props.driver_states.with_quantity_split)) {
          curDriver.PopState(quantity_props.driver_states.with_quantity_split);
        }
      });
      this.children.forEach((plan) => plan.useQuantitySplit(this.Quantity));
      return;
    }

    AllDates.forEach((date) => {
      const curDriver = this.Quantity.getItemByDateSufix(date.sufix);
      curDriver.SaveState(quantity_props.driver_states.single_children_values);
      global.Modeliks.DriverValuesStore.valueUpdated(curDriver.ID);
    });

    this.Quantity_Split.Delete = false;
    this.Quantity.setFormula(`Math.round( ${driver}*${this.Quantity_Split} )`);
  };

  removeQuantitySplit = () => {
    const AllDates = this.getAllDates();
    if (this.IsPortfolio) {
      this.children.forEach((plan) => plan.removeQuantitySplit(this.Quantity));
      AllDates.forEach((date) => {
        const curDriver = this.Quantity.getItemByDateSufix(date.sufix);
        curDriver.SaveState(quantity_props.driver_states.with_quantity_split);
      });

      this.Quantity.setFormula_Sum(this.children.map((plan) => plan.Quantity));
      return;
    }
    this.Quantity_Split.Delete = true;
    this.Quantity.removeFormula();
    AllDates.forEach((date) => {
      const curDriver = this.Quantity.getItemByDateSufix(date.sufix);
      if (curDriver.StateExists(quantity_props.driver_states.single_children_values)) {
        curDriver.PopState(quantity_props.driver_states.single_children_values);
      }
      if (date.Active && date.Order > 0) {
        if (date.hasOwnProperty("months") && date.months.length > 0) {
          const curMonth = this.Quantity.getItemByDateSufix(date.months[6].sufix);
          if (curMonth) {
            curMonth.Formula = `${curDriver}`;
          }
        }
      }
    });
  };

  hasQuantitySplit = () => {
    if (this.IsPortfolio) {
      return this.children[0].hasQuantitySplit();
    }
    return this.Quantity.IsSimple == false && !this.Quantity.Formula.includes("growth");
  };

  #portfolio_GetPlanPeriodsSum = (dateID, field) => {
    const childrenDrivers = this.children.map((plan) => plan.periodsData[dateID][field].toString());
    return `MxMath.Sum([${childrenDrivers.join(",")}])`;
  };

  #set_refund_values = () => {
    this.Refund.Values.forEach((driverValue) => {
      if (driverValue.Date.Active) {
        const childrenDrivers = this.children.map(
          (plan) =>
            plan.periodsData[driverValue.Date.dateID][Subscription.DriversDesc.Refund.fieldName],
        );
        childrenDrivers.forEach((cDriver) => (cDriver.Formula = `${driverValue.ID_f} * 100`));
      }

      const refundDate = this.RefundsNum.getItemByDateSufix(driverValue.Date.sufix);
      const signupsDate = this.Quantity.getItemByDateSufix(driverValue.Date.sufix);
      const refundPercentDate = this.Refund.getItemByDateSufix(driverValue.Date.sufix);
      refundDate.Formula = `${refundDate.Formula} Actual(${signupsDate.ID_f_actual} * ${refundPercentDate.ID_f_actual})`;
    });
  };

  addNewSubscription = (subscription) => {
    if (!subscription) {
      return null;
    }

    subscription.useQuantitySplit(this.Quantity);
    let sumDrivers = [];

    if (this.hasQuantitySplit()) {
      sumDrivers = [
        Subscription.DriversDesc.RefundsNum,
        Subscription.DriversDesc.CustomersAtEndOfPeriod,
        Subscription.DriversDesc.CashCollection,
        Subscription.DriversDesc.SubscriptionRevenue,
        Subscription.DriversDesc.CustomersDueForRenewal,
        Subscription.DriversDesc.ChurnedCustomers,
        Subscription.DriversDesc.RenewingCustomersPostChurn,
        Subscription.DriversDesc.TotalCustomersDueForPayment,
      ];
    } else {
      sumDrivers = [
        Subscription.DriversDesc.Quantity,
        Subscription.DriversDesc.RefundsNum,
        Subscription.DriversDesc.CustomersAtEndOfPeriod,
        Subscription.DriversDesc.CashCollection,
        Subscription.DriversDesc.SubscriptionRevenue,
        Subscription.DriversDesc.CustomersDueForRenewal,
        Subscription.DriversDesc.ChurnedCustomers,
        Subscription.DriversDesc.RenewingCustomersPostChurn,
        Subscription.DriversDesc.TotalCustomersDueForPayment,
      ];
    }

    sumDrivers.forEach((sumDriver) => {
      this[sumDriver.fieldName].Values.forEach((driverValue) => {
        driverValue.Formula = this.#portfolio_GetPlanPeriodsSum(
          driverValue.Date.dateID,
          sumDriver.fieldName,
        );
      });
    });

    this.#set_total_customers_at_start_from_children();
    this.#set_refund_values();

    if (this.hasQuantitySplit()) {
      // subscription.useQuantitySplit(this.Quantity);
    } else {
      subscription.removeQuantitySplit();
      if (this.children[0].Quantity.hasOwnProperty("GrowthDriver")) {
        subscription.Quantity.convertToGrowthDriver();
      }
    }

    const sumSingleValuesDrivers = [
      Subscription.DriversDesc.SinglePrice,
      Subscription.DriversDesc.ChurnRate,
      Subscription.DriversDesc.Quantity_Split,
    ];

    sumSingleValuesDrivers.forEach((sumDriver) => {
      subscription.convertToSingleValue(sumDriver.fieldName);
      if (this.hasSingleValue(sumDriver.fieldName) === true) {
        subscription.removeSingleValue(sumDriver.fieldName);
      }
    });
  };

  removeSubscriptionPlan = (subscription) => {
    if (!subscription) {
      return null;
    }
    this.children.splice(this.children.indexOf(subscription), 1);
    this.children.forEach((c) => c.buildPeriodsData());
    this.buildPeriodsData();

    const sumDrivers = [
      // Subscription.DriversDesc.Quantity,
      Subscription.DriversDesc.RefundsNum,
      Subscription.DriversDesc.CustomersAtEndOfPeriod,
      Subscription.DriversDesc.CashCollection,
      Subscription.DriversDesc.SubscriptionRevenue,
      Subscription.DriversDesc.CustomersDueForRenewal,
      Subscription.DriversDesc.ChurnedCustomers,
      Subscription.DriversDesc.RenewingCustomersPostChurn,
      Subscription.DriversDesc.TotalCustomersDueForPayment,
    ];

    sumDrivers.forEach((sumDriver) => {
      this[sumDriver.fieldName].Values.forEach((driverValue) => {
        driverValue.Formula = this.#portfolio_GetPlanPeriodsSum(
          driverValue.Date.dateID,
          sumDriver.fieldName,
        );
      });
    });

    this.#set_total_customers_at_start_from_children();
    this.#set_refund_values();

    if (this.hasQuantitySplit()) {
      subscription.removeQuantitySplit();
      this.createYearBeforeFormulas();
      this[Subscription.DriversDesc.Quantity.fieldName].Values.forEach((driverValue) => {
        if (
          driverValue.Formula &&
          driverValue.Formula.length > 3 &&
          !driverValue.Formula.includes("growth")
        ) {
          driverValue.Formula = this.#portfolio_GetPlanPeriodsSum(
            driverValue.Date.dateID,
            Subscription.DriversDesc.Quantity.fieldName,
          );
        }
      });
    } else {
      this[Subscription.DriversDesc.Quantity.fieldName].Values.forEach((driverValue) => {
        driverValue.Formula = this.#portfolio_GetPlanPeriodsSum(
          driverValue.Date.dateID,
          Subscription.DriversDesc.Quantity.fieldName,
        );
      });
      subscription.useQuantitySplit();
      subscription.removeQuantitySplit();
    }
  };

  #set_total_customers_at_start_from_children = () => {
    let driver = this.CustomersAtStart.getItemByDateSufix(
      global.Modeliks.DateHelper.months[0].sufix,
    );
    if (!driver && !this.IsPortfolio) {
      return null;
    }
    driver.Formula = `MxMath.Sum([${this.children.map((c) => c.CustomersAtStartInit.ID_f).join(",")}])`;
  };

  #set_churn_rate_totals_formula = () => {
    const years = this.getYearDatesAll();
    this.ChurnRate.setFormula(`${this.ChurnedCustomers} / ${this.CustomersAtStart} * 100`);

    years.forEach((year) => {
      const curYearChurnRate = this.ChurnRate.getItemByDateSufix(year.sufix);
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const ChurnedCustomers = months.map((c) => c.ChurnedCustomers.ID_f).join(",");
      const CustomersAtStart = months.map((c) => c.CustomersAtStart.ID_f).join(",");
      curYearChurnRate.Formula = `(MxMath.Sum([${ChurnedCustomers}]) / MxMath.Sum([${CustomersAtStart}])) * 100`;
    });
  };

  setPortfolioFormulas = () => {
    const sumDrivers = [
      Subscription.DriversDesc.Quantity,
      Subscription.DriversDesc.RefundsNum,
      Subscription.DriversDesc.CustomersAtEndOfPeriod,
      Subscription.DriversDesc.CashCollection,
      Subscription.DriversDesc.SubscriptionRevenue,
      Subscription.DriversDesc.CustomersDueForRenewal,
      Subscription.DriversDesc.ChurnedCustomers,
      Subscription.DriversDesc.RenewingCustomersPostChurn,
      Subscription.DriversDesc.TotalCustomersDueForPayment,
    ];

    sumDrivers.forEach((sumDriver) => {
      this[sumDriver.fieldName].Values.forEach((driverValue) => {
        driverValue.Formula = this.#portfolio_GetPlanPeriodsSum(
          driverValue.Date.dateID,
          sumDriver.fieldName,
        );
      });
    });

    this.#set_total_customers_at_start_from_children();
    this.#set_churn_rate_totals_formula();
    this.SinglePrice.setFormula(`${this.CashCollection} / ${this.TotalCustomersDueForPayment}`);
    this.removeSinglePriceActualFormula();

    this.#set_refund_values();

    this.useQuantitySplit();
    this.convertToSingleValue(Subscription.DriversDesc.SinglePrice.fieldName);
    this.convertToSingleValue(Subscription.DriversDesc.ChurnRate.fieldName);
    this.convertToSingleValue(Subscription.DriversDesc.Quantity_Split.fieldName);
  };

  setFormulasAfterRemovingSetFormulaOnSignsUps = () => {
    this.removeQuantitySplit();
  };

  removeSinglePriceActualFormula = () => {
    const months = this.getMonthDatesAll();

    months.forEach((month) => {
      const curMonth = this.periodsData[month.dateID];
      curMonth.SinglePrice.evalFormulaActual = null;
    });
  };

  setSubscription = () => {
    if (this.IsPortfolio) {
      this.children.forEach((child) => {
        if (
          child.db_record &&
          child.SubscriptionPeriod.cur_record.Value !== child.SubscriptionPeriod.db_record.Value
        ) {
          child.createMonthsFormulas();
        }
      });
      this.setTotalsFormula();
    }
  };

  addSubscriptionPlan = (plans, callBack) => {
    this.children.forEach((c) => c.buildPeriodsData());
    let counter = 0;
    const results = plans.filter((d) => !this.children.some((c) => d.ID === c.ID));
    if (results.length > 0) {
      results.forEach((plan) => {
        Revenue.GetEmptyRevenue((revenue) => {
          counter = counter + 1;
          let newPlan = Subscription.createSubscriptionPlan(
            Subscription.convert_plan(revenue, this.ID),
            plan.SubscriptionName,
            plan.SubscriptionPeriodMonths,
          );
          newPlan.changeDriversName();
          plan.ID = newPlan.ID;
          this.children.push(newPlan);
          this.addNewSubscription(newPlan);
          if (counter === results.length) {
            this.setSubscription();
            callBack(this);
          }
        });
      });
    } else {
      callBack(this);
    }

    return this;
  };

  addNewSubscriptionPlan = (callBack) => {
    const newPlans = this.subscriptionplans.filter((s) => s.ID == null);
    const removePlans = this.subscriptionplans.filter((s) => s.Delete);
    let counter = 0;
    if (newPlans.length > 0) {
      this.children.forEach((c) => c.buildPeriodsData());
      const dbRecords = [];
      newPlans.forEach((plan, index) => {
        Revenue.GetEmptyRevenue((revenue) => {
          dbRecords.push(revenue);
          if (dbRecords.length === newPlans.length) {
            dbRecords.sort((a, b) => a.ID - b.ID);
            for (let i = 0; i < dbRecords.length; i++) {
              const dbR = dbRecords[i];
              const subR = newPlans[i];
              subR.ID = dbR.ID;
              const newSPlan = Subscription.convert_plan(
                { ID: subR.ID, Name: subR.SubscriptionName },
                this.ID,
              );
              const newPlan = Subscription.createSubscriptionPlan(
                newSPlan,
                subR.SubscriptionName,
                subR.SubscriptionPeriodMonths,
              );
              newPlan.changeDriversName();
              newPlan.IsCreated = true;
              this.children.push(newPlan);
              this.addNewSubscription(newPlan);
            }

            this.setSubscription();
            callBack(this);
          }
        });
      });
    } else {
      callBack(this);
    }
  };

  static createSubscriptionPlan = (newSubs, Name, SubscriptionPeriod) => {
    let newSubscription = newSubs;
    newSubscription.Totals = CalculatedDriver.createDriverFromTable(
      newSubscription,
      Revenue.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newSubscription.RevenueName = Name;
    newSubscription.RevenueType = RevenueTypes.Subscriptions;

    newSubscription.SubscriptionPeriod = new CalculatedDriver_Values(
      null,
      newSubscription.getSubscriptionPeriodID(),
      null,
      UnitTypes.Units,
    );
    newSubscription.SubscriptionPeriod.Value = SubscriptionPeriod;

    newSubscription.CustomersAtStartInit = new CalculatedDriver_Values(
      null,
      newSubscription.getCustomersAtStartInitID(),
      null,
      UnitTypes.Units,
    );
    newSubscription.CustomersAtStartInit.Value = 0;

    newSubscription.createDrivers();
    newSubscription.createExtraPeriods();
    newSubscription.createYearBeforeFormulas();
    newSubscription.createMonthsFormulas();
    newSubscription.createYearsFormulas();
    newSubscription.setTotalsFormula();

    return newSubscription;
  };

  static createSubscriptionsPortfolio = (subs, plans) => {
    // const newSubscription = Subscription.createSubscriptionPlan(revenue.ID, revenue.RevenueName, 0);
    subs.children = plans;
    subs.setPortfolioFormulas();
    return subs;
  };

  static createSubscription = (revenue) => {
    const subscriptions = [];
    revenue.subscriptionplans.forEach((plan) =>
      subscriptions.push(
        Subscription.createSubscriptionPlan(
          Subscription.createDbRecord(),
          plan.SubscriptionName,
          plan.SubscriptionPeriodMonths,
        ),
      ),
    );
    revenue = Subscription.createSubscriptionsPortfolio(revenue, subscriptions);

    return revenue;
  };

  static convert_plan = (revenue, parentID) => {
    const plan = new Subscription();
    if (parentID) {
      plan.ID_ParentRevenue = parentID;
    }

    plan.ID = revenue.ID;
    plan.RevenueName = revenue.Name;
    return plan;
  };

  static createDbRecord = (callBack, parentID) => {
    const newSubs = new Subscription();
    if (parentID) {
      newSubs.ID_ParentRevenue = parentID;
    }

    newSubs.SaveRevenue(
      (id) => {
        newSubs.ID = id;
        if (callBack) {
          callBack(newSubs);
        } else {
          return newSubs;
        }
      },
      false,
      false,
    );
  };

  static convert_Revenue = (oldRevenue, callBack) => {
    let newSub = new Subscription();
    newSub.ID = oldRevenue.ID;
    newSub.IsCreated = true;
    newSub.RevenueName = oldRevenue.RevenueName;
    newSub.subscriptionplans = oldRevenue.subscriptionplans;
    const subscriptions = [];
    const dbRecords = [];

    newSub.subscriptionplans.forEach((plan, index) => {
      Revenue.GetEmptyRevenue((revenue) => {
        dbRecords.push(revenue);
        if (dbRecords.length === newSub.subscriptionplans.length) {
          dbRecords.sort((a, b) => a.ID - b.ID);
          for (let i = 0; i < dbRecords.length; i++) {
            const dbR = dbRecords[i];
            const subR = newSub.subscriptionplans[i];
            subR.ID = dbR.ID;
            const newSPlan = Subscription.convert_plan(
              { ID: subR.ID, Name: subR.SubscriptionName },
              newSub.ID,
            );
            const newPlan = Subscription.createSubscriptionPlan(
              newSPlan,
              subR.SubscriptionName,
              subR.SubscriptionPeriodMonths,
            );
            newPlan.IsCreated = true;
            subscriptions.push(newPlan);
          }
          newSub.children = subscriptions;
          newSub = Subscription.createSubscriptionPlan(newSub, oldRevenue.RevenueName);
          newSub.setPortfolioFormulas();
          newSub.children[0].Quantity_Split.getSingleDriverValue().Value = 100;
          callBack(newSub);
        }
      });
    });
  };

  static getSubscriptionsCashCollections = () => {
    const driverID = "cash_collections";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Cash Collections",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
    }

    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.CashCollection);
    if (totalDrivers.length > 0) {
      driver.setFormula_Sum(totalDrivers);
    }

    return driver;
  };

  static getSubscriptionsTotals = () => {
    const driverID = "subscription_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Subscription Revenue",
        true,
        false,
        false,
      );
    }

    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.Totals);
    if (totalDrivers.length > 0) {
      driver.setFormula_Sum(totalDrivers);
    }

    return driver;
  };

  static getTotalCustomersDueForPayment = () => {
    const driverID = "total_customers_due_payment";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Customers Due For Payment",
        true,
        true,
        false,
      );
    }
    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.TotalCustomersDueForPayment);

    if (totalDrivers.length > 0) {
      driver.setFormula_Sum(totalDrivers);
    }

    return driver;
  };

  static getSubscriptionsTotalsReport = () => {
    const driverID = "subscription_report";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-total",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "total",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Subscription Revenue",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
    }
    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.Totals);
    if (totalDrivers && totalDrivers.length > 0) {
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `${totalDrivers.map((d) => d.getItemByDateSufix(date.sufix)).join("+")}  Actual(MxMath.Sum([${totalDrivers.map((m) => m.getItemByDateSufix(date.sufix).ID_f_actual).join(",")}]))`;
      });
    }

    return driver;
  };

  static getCustomersAtEndOfPeriodTotals = () => {
    const driverID = "customers_at_end_of_period_reports_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.LastPeriod,
        "Total Customers at end of period",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
    }
    driver.LastPeriodOnly = true;

    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.CustomersAtEndOfPeriod);
    if (totalDrivers && totalDrivers.length > 0) {
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `${totalDrivers.map((d) => d.getItemByDateSufix(date.sufix)).join("+")}`;
      });
    }

    return driver;
  };

  static getCustomersAtStartTotals = () => {
    const driverID = "customers_at_start_of_period_reports_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Customers at start",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
    }

    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.CustomersAtStart);
    if (totalDrivers && totalDrivers.length > 0) {
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `${totalDrivers.map((d) => d.getItemByDateSufix(date.sufix)).join("+")}`;
      });
    }

    return driver;
  };

  static getNumberOfRefundsTotals = () => {
    const driverID = "number_of_refunds_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Number of Refunds",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
      driver.isExpense = true;
    }

    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.RefundsNum);
    if (totalDrivers && totalDrivers.length > 0) {
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `${totalDrivers.map((d) => d.getItemByDateSufix(date.sufix)).join("+")}`;
      });
    }

    return driver;
  };

  static getChurnedCustomersTotals = () => {
    const driverID = "churned_customers_reports_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Churned Customers",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
      driver.isExpense = true;
    }

    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).map((d) => d.ChurnedCustomers);
    if (totalDrivers && totalDrivers.length > 0) {
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `${totalDrivers.map((d) => d.getItemByDateSufix(date.sufix)).join("+")}`;
      });
    }

    return driver;
  };

  static getSingupsTotals = () => {
    const driverID = "signups_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Signups",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
    }
    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    ).flatMap((d) => d.children);
    if (totalDrivers && totalDrivers.length > 0) {
      const totalQuantity = global.Modeliks.RevenuesStore.filter(
        (d) => d.RevenueType === RevenueTypes.Subscriptions,
      );
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `MxMath.Sum([${totalDrivers.map((m) => m.Quantity.getItemByDateSufix(date.sufix)).join(",")}]) Actual(MxMath.Sum([${totalQuantity.map((m) => m.Quantity.getItemByDateSufix(date.sufix).ID_f_actual).join(",")}]))`;
      });
    }

    return driver;
  };

  static getSinglePriceTotals = () => {
    const driverID = "single_price_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Average,
        "Total Subscription Price",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
    }
    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    );
    if (totalDrivers && totalDrivers.length > 0) {
      const totalCashCollections = totalDrivers.map((d) => d.CashCollection);
      const totalCustomersDueForPayment = totalDrivers.map((d) => d.TotalCustomersDueForPayment);
      driver.Formula = `(${totalCashCollections.map((d) => d).join("+")}) /  (${totalCustomersDueForPayment.map((d) => d).join("+")})`;

      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        curDate.Formula = `MxMath.Sum([${totalCashCollections.map((m) => m.getItemByDateSufix(date.sufix)).join(",")}]) / MxMath.Sum([${totalCustomersDueForPayment.map((m) => m.getItemByDateSufix(date.sufix)).join(",")}]) Actual(MxMath.Sum([${totalCashCollections.map((m) => m.getItemByDateSufix(date.sufix).ID_f_actual).join(",")}]) / MxMath.Sum([${totalCustomersDueForPayment.map((m) => m.getItemByDateSufix(date.sufix).ID_f_actual).join(",")}]))`;
      });
    }

    return driver;
  };

  static getChurnRateTotals = () => {
    const driverID = "churn_rate_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Percentage,
        DriverCategories.Average,
        "Total Churn Rate",
        true,
        true,
        false,
      );
      driver.addMonths(48, true);
      const CustomersAtStartTotals = Subscription.getCustomersAtStartTotals();
      const ChurnedCustomersTotals = Subscription.getChurnedCustomersTotals();
      const allDates = global.Modeliks.DateHelper.all_periods;
      allDates.forEach((date) => {
        const curDate = driver.getItemByDateSufix(date.sufix);
        const curChurnedCustomers = ChurnedCustomersTotals.getItemByDateSufix(date.sufix);
        const curCustomersAtStart = CustomersAtStartTotals.getItemByDateSufix(date.sufix);

        if (date.PeriodType === PeriodTypes.month) {
          curDate.Formula = `${curChurnedCustomers} / ${curCustomersAtStart}  * 100`;
        } else {
          const curYearMonthsCustomersAtStart = `MxMath.Sum([${date.months.map((m) => CustomersAtStartTotals.getItemByDateSufix(m.sufix)).join(",")}])`;
          const curYearMonthsChurnedCustomers = `MxMath.Sum([${date.months.map((m) => ChurnedCustomersTotals.getItemByDateSufix(m.sufix)).join(",")}])`;
          curDate.Formula = `${curYearMonthsChurnedCustomers} / ${curYearMonthsCustomersAtStart} * 100`;
        }
      });
    }
    return driver;
  };

  static getCumulativeTotalSubscriptionRevenue = () => {
    const driverID = "cumulative_subscription_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Cumulative Total Subscription Revenue",
        true,
      );
    }

    const subscriptionTotals = Subscription.getSubscriptionsTotals();

    const months = global.Modeliks.DateHelper.months;
    const years = global.Modeliks.DateHelper.years_all;

    months.forEach((month) => {
      const curMonth = driver.getItemByDateSufix(month.sufix);
      if (month.Order > 0) {
        const monthBefore = months.find((m) => m.Order === curMonth.Order - 1);
        curMonth.Formula = `${subscriptionTotals.getItemByDateSufix(month.sufix)} + ${subscriptionTotals.getItemByDateSufix(monthBefore.sufix)}`;
      } else {
        curMonth.Formula = `${subscriptionTotals.getItemByDateSufix(month.sufix)}`;
      }
    });

    years.forEach((year) => {
      const curYear = driver.getItemByDateSufix(year.sufix);
      const lastMonth = driver.getItemByDateSufix(year.months[year.months.length - 1].sufix);
      curYear.Formula = `${lastMonth}`;
    });

    return driver;
  };

  static getCumulativeSubscriptionCashColections = () => {
    const driverID = "cumulative_cash_collections";
    let driver = global.Modeliks.DriversStore.getItem(
      Subscription.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Subscription.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Cumulative Subscription Cash Collections",
        true,
      );
    }

    const cashCollections = Subscription.getSubscriptionsCashCollections();

    const months = global.Modeliks.DateHelper.months;
    const years = global.Modeliks.DateHelper.years_all;

    months.forEach((month) => {
      const curMonth = driver.getItemByDateSufix(month.sufix);
      if (month.Order > 0) {
        const monthBefore = months.find((m) => m.Order === curMonth.Order - 1);
        curMonth.Formula = `${cashCollections.getItemByDateSufix(month.sufix)} + ${cashCollections.getItemByDateSufix(monthBefore.sufix)}`;
      } else {
        curMonth.Formula = `${cashCollections.getItemByDateSufix(month.sufix)}`;
      }
    });

    years.forEach((year) => {
      const curYear = driver.getItemByDateSufix(year.sufix);
      const lastMonth = driver.getItemByDateSufix(year.months[year.months.length - 1].sufix);
      curYear.Formula = `${lastMonth}`;
    });

    return driver;
  };
}

export default Subscription;
